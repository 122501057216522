import { modal_checkboxAll_monitoring } from '../jobSearch/search-modal-checkboxAll-monitoring';

export function jobSearch_modal_selectArea () {

  /* ------------------------------------------------------------
  共通設定
  ------------------------------------------------------------ */

  // モーダルを開いたときの状態を保存
  var $cond_default_nearHome_checkbox = $('#searchCond-nearHome:checked')[0],
      $cond_default_nearHome_select = $('#searchCond-nearHomeSelect select').val(),
      $cond_default_nearHome_text = $('.c-searchCond-condTable__text.is-nearHome').text(),
      $cond_default_textWrapper_class = $('#searchCond-area').attr('class'),
      $cond_default_text_area_class = $('.c-searchCond-condTable__text.is-area').attr('class'),
      $cond_default_text_nearHome_class = $('.c-searchCond-condTable__text.is-nearHome').attr('class');



  /* ------------------------------------------------------------
  「エリアから探す」タブ
  ------------------------------------------------------------ */

  // エリア選択 > 選択状態を復元
  // メモ : 「閉じる」ボタンで確定しなかった場合のために、モーダルを開くごとに処理
  function restoreCheckedObj ($checkedVal) {
    if ($checkedVal) {
      // 隠しinputの値を配列に代入
      var $array_checeked = $checkedVal.split(",");
      for (var i=0; i<$array_checeked.length; i++) {
        $('.js-area').each(function(){
          if ($(this).val() === $array_checeked[i]) {
            $(this).prop('checked', true);
          }
        })
      }
    }
  }
  restoreCheckedObj($('.js-searchcond-area').val());
  // モーダル > 一括チェックの状態復帰
	$('.c-modal-select-lv1__heading .c-checkbox__input').each(function(){
    modal_checkboxAll_monitoring($(this));
  });


	// 「選択する」ボタンで反映処理 : エリアから選択
	$('#modal-selectArea-area').off('click').on('click', function(){
    var $chekedObj = $('.js-area:checked'),
        $chekedObjVal = null,
        $targetInput = $('.js-searchcond-area');
		if ($chekedObj[0]) {
			var $chekedAreaText = null;
			// チェックされた県名を"/"区切りで連結
			$chekedObj.each(function(){
        var $text = $(this).next().text();
        if ($(this).parents('.js-selectArea-local').length){
          $text = $(this).parents('.js-selectArea-local').prev('.c-modal-select-lv1__heading').find('.c-checkbox__label').text() + '-' + $text;
        }
				if (!$chekedAreaText) {
					$chekedAreaText = $text;
          $chekedObjVal = $(this).val();
				} else {
					$chekedAreaText = $chekedAreaText + '/' + $text;
          $chekedObjVal = $chekedObjVal + ',' + $(this).val();
				}
      });
    } else {
      $chekedAreaText = '-';
      $chekedObjVal = '';
    }
			// 選択したエリアを反映
			$('.c-searchCond-condTable__text.is-area').text($chekedAreaText).removeClass('is-hidden');
			$('.c-searchCond-condTable__text.is-nearHome').addClass('is-hidden');
			$($targetInput).val($chekedObjVal).prop('disabled', false);
      // テキストエリアの不活性化を解除
      $('#searchCond-area').removeClass('is-disabled');
			// 本体 > 自宅近くチェックを外して不活性化
      $('#searchCond-nearHome').prop('checked', false);
      $('#searchCond-nearHomeSelect').addClass('is-disabled').find('select').prop('disabled', true);
      // モーダル > 自宅近くでさがす > セレクトボックス を不活性化
      $('.c-modal-nearHome-select select').prop('disabled', true);
    // モーダルを閉じる
    $('.js-modal-searchCond--area').modaal('close');
	});



  /* ------------------------------------------------------------
  「自宅の近くから探す」タブ
  ------------------------------------------------------------ */

  // 本体の「自宅の近くで探す」をモーダルに移動
  $('.js-searchCond-nearHome-input').appendTo('.c-modal-nearHome__container');

	// 「自宅近くでさがす > 選択する」ボタンで反映処理 :
	$('#modal-selectArea-nearHome').off('click').on('click', function(){
		// 「自宅の近くでさがす」 > チェックボックスをチェック
		$('#searchCond-nearHome').prop('checked', true);
    // エリア指定の隠しinputを不活性化
    $('.js-searchcond-area').prop('disabled', true);

    var $target = $('#searchCond-nearHomeSelect select'),
        $targetText = $('#searchCond-nearHomeSelect option[value="' + $target.val() + '"]').text();
    $('.c-searchCond-condTable__text.is-nearHome').text('自宅近くからさがす/' + $targetText).removeClass('is-hidden');
    $('.c-searchCond-condTable__text.is-area').addClass('is-hidden');

		// PC表示のときは、本体のエリア指定を不活性化
		if (window.innerWidth >= 768) {
			$('#searchCond-area').addClass('is-disabled');
		}
    // モーダルを閉じる
		$('.js-modal-searchCond--area').modaal('close');
  });

  // 閉じるボタンをクリック
  $('#modal-selectArea .c-modal-button__closeBtn').off('click').on('click', function(){
    // 自宅の近くで探す > セレクトボックスの選択を復元
    $('#searchCond-nearHomeSelect select option[value="' + $cond_default_nearHome_select + '"]').prop('selected', true);
    // SP表示用のテキストを復元
    $('.c-searchCond-condTable__text.is-nearHome').text($cond_default_nearHome_text);
    $('.c-searchCond-condTable__text.is-area').attr('class', $cond_default_text_area_class);
    $('.c-searchCond-condTable__text.is-nearHome').attr('class', $cond_default_text_nearHome_class);
    $('#searchCond-area').attr('class', $cond_default_textWrapper_class);
    // 自宅の近くで探す > チェックボックス / セレクトボックスの活性状態を復元
    if ($cond_default_nearHome_checkbox) {
      $('#searchCond-nearHome').prop('checked', true);
      $('#searchCond-nearHomeSelect select').prop('disabled', false);
			$('.js-searchcond-area').prop('disabled', true);
    } else {
      $('#searchCond-nearHome').prop('checked', false);
      $('#searchCond-nearHomeSelect select').prop('disabled', true);
			$('.js-searchcond-area').prop('disabled', false);
    }
    // モーダルを閉じる
		$('.js-modal-searchCond--area').modaal('close');
  });
}