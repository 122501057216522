// お仕事詳細 > SP表示 > 電話で応募ボタンクリックでウィンドウの開閉
$(function(){
	// ウィンドウを開く
	$('.js-jobApplyTel-open').on('click', function(e) {
		e.preventDefault();
		$(this).next('.js-jobApplyTel-window').addClass('is-opened');
	});
	// ウィンドウを閉じる
	$('.js-jobApplyTel-close').on('click', function(e) {
		e.preventDefault();
		$('.js-jobApplyTel-window').removeClass('is-opened');
	});
});