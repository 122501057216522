import {modalOpen_forNotLoggedIn} from '../modal/modal-open';

$(function() {
  $.ajax({
    url: '/auth/info',
    method: 'GET',
    timeout: 10000,
  }).then(function(data) {
    if (data) {
      // ログインしてるときの表示
      var $userName       = data.user.name,
          $favoriteCount  = data.favoriteCount,
          $html = '<p class="c-header-login__name">' + $userName + 'さん</p>' +
            '<ul class="c-header-login-nav__list">' +
              '<li class="c-header-login-nav__item"><a href="/mypage" class="c-header-login-nav__anchor is-myPage">マイページ</a></li>' +
              '<li class="c-header-login-nav__item"><a href="/mypage/setting" class="c-header-login-nav__anchor is-memberInfo">会員情報</a></li>' +
              '<li class="c-header-login-nav__item"><a href="/logout" class="c-header-login-nav__anchor is-logout">ログアウト</a></li>' +
            '</ul>';
      $('.c-header-login').addClass('is-login').html($html).removeClass('is-veiled');
      $('.c-header-favorit__number').text($favoriteCount);
      $('.c-header-favorit').removeClass('is-veiled');
    } else {
      // ログインしてないときの表示
      modalOpen_forNotLoggedIn();
      // スケルトン表示の除去
      $('.c-header-login').removeClass('is-veiled');
      $('.c-header-favorit').removeClass('is-veiled');
    }
  }).catch(function(err) {
    if (err.status === 0) {
      return;
    }
    alert('ログイン状態が取得できませんでした。時間を置いて再度アクセスお願いします。');
    $('body').addClass('is-notLoggedIn');
    // スケルトン表示の除去
    $('.c-header-login').removeClass('is-veiled');
    $('.c-header-favorit').removeClass('is-veiled');
  });
});