$(function(){
  // モーダル > 一括チェック
	$('.js-checkAll').on('change', function(){
    var $target = $(this).attr('data-checkalltarget');
		if ( $(this).prop('checked') ) {
      $($target).prop('checked', true).trigger('change');
		} else {
			$($target).prop('checked', false).trigger('change');
		}
  });
});