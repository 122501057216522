$(function () {
  if ($('.c-job-staff')[0]) {
    // Note: br以外のタグが入ることは意図していないので壊れる
    var $staffComment = $('.c-job-staff__descriptionInner')
      .html()
      .replace(/^(\s+)(.*)(\s+)/gm, '$2') // remove indent
      .replace(/(\n|\r|\r\n)/g, '') // remove break lines
      .replace(/(<br\s*\/?>)/g, '\n'); // replace `br` tag

    var firstEol = $staffComment.indexOf('\n');
    var splitIndex = firstEol === -1 ? 45 : firstEol;
    var $staffComment_1 = $staffComment.slice(0, splitIndex);
    var $staffComment_2 = $staffComment.slice(splitIndex);
    // 最初の改行までもしくは45文字目までを表示、それ以降を隠す
    $('.c-job-staff__descriptionInner')
      .text('')
      .append(
        '<span class="is-default">' +
          $staffComment_1.replace(/\n/g, '<br>') +
          '</span><span class="is-ellipsis"></span><span class="is-more" style="display: none;">' +
          $staffComment_2.replace(/\n/g, '<br>') +
          '</span>'
      );
    // もっと見る をクリックで、全て表示
    $('.c-job-staff__anchor').on('click', function (e) {
      e.preventDefault();
      // 「…」と「もっと見る」を削除
      $(
        '.c-job-staff__descriptionInner .is-ellipsis, .c-job-staff__anchor'
      ).remove();
      // 非表示部分をフェードイン
      $('.c-job-staff__descriptionInner .is-more').fadeIn();
    });
  }
});
