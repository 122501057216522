// スムーススクロール
$(function(){
	$('a[href^="#"]:not([href="#"])').on('click', function(e){
    e.preventDefault();
    var $target = $($(this).attr('href')),
        $adjuster = 16;
    if ($target[0]) {
			var $targetOffsetTop = $target.offset().top - $adjuster;
      $('body,html').animate({
        scrollTop: $targetOffsetTop
      }, 400);
  }
	})
})