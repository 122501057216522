$(function () {
  const errorMessage = {
    show: function (message, $button) {
      const $messageHtml = $('<div id="js-errorMessage" class="c-alert c-alert--danger">' +
        '<p class="c-alert__message"></p>' +
        '</div>');
      $messageHtml.find('.c-alert__message').text(message);
      $button.closest('.p-myPage-btnList').before($messageHtml);
    },
    hide: function () {
      $("#js-errorMessage").remove();
    },
  };

  $("#favorites-form input[type=checkbox]").on("change", function (event) {
    errorMessage.hide();
  });

  $("#js-favoriteToApplicationButton").on("click", function (event) {
    event.preventDefault();
    const $target = $(event.target);
    const formId = $target.attr("form");
    const $form = $("#" + formId);

    const $closedItems = $form.find(
      "input[type=checkbox][data-is-closed]:checked"
    );
    $closedItems.prop("checked", false).trigger("change");
    errorMessage.hide();

    const $notClosedCheckedItems = $form.find(
      "input[type=checkbox]:not([data-is-closed]):checked"
    );
    const $onlySilgleEntryItems = $notClosedCheckedItems.filter('[data-is-only-single]');

    if ($notClosedCheckedItems.length === 0) {
      // 掲載中仕事のチェックが0件の場合はエラー
      let message = "応募したいお仕事情報にチェックを付けてください";
      if ($closedItems.length) {
        message += "（募集終了しているお仕事には応募できません）";
      }
      errorMessage.show(message, $target);
    } else if ($onlySilgleEntryItems.length && $onlySilgleEntryItems.length !== $notClosedCheckedItems.length) {
      const jobIds = [];
      $onlySilgleEntryItems.each(function(idx, elem) {
        jobIds.push('お仕事No.' + $(elem).val());
      });
      errorMessage.show(jobIds.join('、')+' はまとめて応募ができません。1件ずつご応募ください。', $target);
    } else {
      $form.find("input[type=hidden]").prop("disabled", true);
      $form
        .attr("method", $target.attr("formmethod"))
        .attr("action", $target.attr("formaction"))
        .submit();
    }
  });

  $("#js-favoriteDeleteButton").on("click", function (event) {
    event.preventDefault();
    const $target = $(event.target);
    const formId = $target.attr("form");
    const $form = $("#" + formId);
    const checkedCount = $form.find("input[type=checkbox]:checked").length;
    errorMessage.hide();

    if (checkedCount === 0) {
      let message = "削除したいお仕事情報にチェックを付けてください";
      errorMessage.show(message, $target);
      $target.data('modal-allow', false);
    } else {
      $form.find("input[type=hidden]").prop("disabled", false);
      $form
        .attr("method", $target.attr("formmethod"))
        .attr("action", $target.attr("formaction"));
      $target.data('modal-allow', true)
        .trigger('modal.open');
    }
  });
});

