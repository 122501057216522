$(function() {
  if ($('.js-rejectRepeatedClick')[0]){
   $('.js-rejectRepeatedClick').on('submit', function(e){
      var _this = $(this),
          _btn = _this.find('[type=submit]');
      if (!_btn[0]) {
        _btn = _this.find('button.c-button__btn');
      }
      if (_btn.prop('disabled')) {
        e.preventDefault();
      }
      _btn.prop('disabled', true);
    });
  }
});