// 該当の日程に面接の空きがない場合の戻る処理
$(function(){
	$(document).on('click', '.js-apply-goBack-selectDate', function(){
		var $wrapper = $(this).parents('.js-applyType--target'),
				$target = $wrapper.prev('.js-applyType--target').offset().top;
		// 次のブロックの先頭までスクロール
		$('body,html').animate({
			scrollTop: $target
		}, 400);
		$wrapper.hide();
	})
})
