$(function (){
	// 初期状態の生成
	if ( !$('.c-job-images-largeImg').hasClass('is-initialized') && $('.c-job-images__item')[0] ) {
		var $imgSrcInit = $('.c-job-images__item:first-of-type .c-job-images__image img').clone();
		var $imgTxtInit = $('.c-job-images__item:first-of-type .c-job-images__text').text();
		$('.c-job-images-largeImg__image').append($imgSrcInit);
		$('.c-job-images-largeImg__text').text($imgTxtInit);
		$('.c-job-images-largeImg').addClass('is-initialized');
	}
	// 大画像・テキストの差し替え
	$('.c-job-images__image img').on('click', function(){
		// PC表示のときのみ実行
		if (window.innerWidth >= 768) {
			// クリックされたサムネールの画像URL/テキストを変数に格納
			var $imgSrc = $(this).attr('src');
			var $imgTxt = $(this).parents('.c-job-images__item').find('.c-job-images__text').text();
			// 画像/テキストの差し替え処理
			$('.c-job-images-largeImg__image img').attr('src', $imgSrc);
			$('.c-job-images-largeImg__text').text($imgTxt);
		}
	});
});