import { mordal_accordion } from '../modal/modal-accordion';
import { jobSearch_modal_selectArea } from '../jobSearch/search-selectArea';
import { jobSearch_modal_selectArea_finish } from '../jobSearch/search-cond-area-modal_finish';
import { jobSearch_modal_select } from '../jobSearch/search-modal-select';
import { searchCondSalaryModal_ready } from '../jobSearch/search-cond-salaryModal_ready';
import { searchCondSalaryModal_finish } from '../jobSearch/search-cond-salaryModal_finish';
import { esGraduatedYearCalc } from '../entrysheet/entrysheet-GraduatedYearCalc';
import { esSelectWorkingPlace } from '../entrysheet/entrysheet-selectWorkingPlace';
import { meetingSheduleChangeSetData } from '../myPage/myPage-modal-meetingSheduleChange-setdata';
import { search_resultCount } from '../jobSearch/search-resultCount';
import { qualificatonSearchVM } from '../entrysheet/entrysheet-qualification';

export function modalOpen_forNotLoggedIn (){
  $(function(){
    var $modalBgColor = "#fff",
        $modalBgOpacity = ".95";
  
    // ヘッダー > お気に入り > 会員登録サジェスト
    $('.js-modalOpen-favoritSuggestion').modaal({
      type: 'ajax',
      background: $modalBgColor,
      overlay_opacity: $modalBgOpacity,
      animation_speed: 400,
      content_source: $('.js-modalOpen-favoritSuggestion').attr('data-modal-source'),
      // 下記オプションでajaxがエラー（含むTimeout）時のエラーメッセージを変更可能
      // デフォルトのエラーメッセージは、_src/asset/js/vendor/modaal.withTimeout.js の1150行目あたりにあります。
      //ajax_error_contgent: '<p class="c-loading__errMessage"><span class="u-display--inlineBlock">データが取得できませんでした。</span><span class="u-display--inlineBlock">再度お試しください。</span><button class="c-button__btn--negative js-modal-close">閉じる</button></p>'
    });
  });
}

$(function(){

	function modalHeightAdjust () {
		var winHeight = window.innerHeight;
		$('.c-modal').height(winHeight);
		$('.c-modal-nearHome').css('height', 'calc(' + winHeight + 'px - 98px)');
		$('.c-modal-nearHome__container').css('height', 'calc(' + winHeight + 'px - 98px - 80px)');
	}

	var $modalBgColor = "#fff";
	var $modalBgOpacity = ".95";

	// トップページ > 寮完備
	/*
	$('.js-modal-dormitory-areaSelect').modaal({
		type: 'inline',
		background: $modalBgColor,
		overlay_opacity: $modalBgOpacity,
		animation_speed: 400,
		content_source: $('.js-modal-dormitory-areaSelect').attr('data-modal-source'),
	});
	*/

	// トップページ/お仕事検索 > 自宅周辺からさがす > 会員登録サジェスト
	$('.js-modal-nearHome-registrationSuggest').modaal({
		type: 'inline',
		background: $modalBgColor,
		overlay_opacity: $modalBgOpacity,
		animation_speed: 400,
    content_source: $('.js-modal-nearHome-registrationSuggest').attr('data-modal-source'),
	});

	// お仕事検索 > エリア指定
	$('.js-modal-searchCond--area').modaal({
		type: 'inline',
		background: $modalBgColor,
		overlay_opacity: $modalBgOpacity,
		animation_speed: 400,
		overlay_close: false,
		hide_close: true,
		//start_open: true,
		content_source: $('.js-modal-searchCond--area').attr('data-modal-source'),
		before_open : function(){
      jobSearch_modal_selectArea();
      mordal_accordion();
		},
		after_open: function() {
			this.$elem.trigger('modal.opened');
		},
		before_close : function(){
      jobSearch_modal_selectArea_finish();
		},
		after_close : function(){
      search_resultCount();
		}
	});

	// お仕事検索 > 業種・職種指定
	$('.js-modal-searchCond--industryType').modaal({
		type: 'inline',
		background: $modalBgColor,
		overlay_opacity: $modalBgOpacity,
		animation_speed: 400,
		content_source: $('.js-modal-searchCond--industryType').attr('data-modal-source'),
		after_open : function(){
			jobSearch_modal_select('.js-industryType', '.js-modal-searchCond--industryType', '.js-searchcond-industryType');
			jobSearch_modal_select('.js-occupation', '.js-modal-searchCond--industryType', '.js-searchcond-occupation');
		},
		after_close : function(){
      search_resultCount();
		}
	});

	// お仕事検索 > 給与指定
	$('.js-modal-searchCond--salary').modaal({
		type: 'inline',
		background: $modalBgColor,
		overlay_opacity: $modalBgOpacity,
		animation_speed: 400,
		hide_close: true,
		content_source: $('.js-modal-searchCond--salary').attr('data-modal-source'),
		before_open : function(){
			searchCondSalaryModal_ready();
		},
		before_close : function(){
			searchCondSalaryModal_finish();
		},
		after_close : function(){
      search_resultCount();
		}
	});

	// お仕事検索 > こだわり条件指定
	$('.js-modal-searchCond--cond').modaal({
		type: 'inline',
		background: $modalBgColor,
		overlay_opacity: $modalBgOpacity,
		animation_speed: 400,
		content_source: $('.js-modal-searchCond--cond').attr('data-modal-source'),
		after_open : function(){
			jobSearch_modal_select('.js-cond', '.js-modal-searchCond--cond', '.js-searchcond-cond');
		},
		after_close : function(){
      search_resultCount();
		}
	});

	// お気に入り登録サジェスト
	$('.js-job-favoritBtn[data-modal-source]').modaal({
		type: 'ajax',
		background: $modalBgColor,
		overlay_opacity: $modalBgOpacity,
		animation_speed: 400,
		content_source: $('.js-job-favoritBtn[data-modal-source]').attr('data-modal-source'),
	});

	// お仕事詳細 > ヘルプ : 休日・休暇
	$('.js-modal-jobDetail-help--holiday').modaal({
		type: 'inline',
		background: $modalBgColor,
		overlay_opacity: $modalBgOpacity,
		animation_speed: 400,
		content_source: $('.js-modal-jobDetail-help--holiday').attr('data-modal-source'),
	});

	// お仕事詳細 > ヘルプ : 寮
	$('.js-modal-jobDetail-help--dormitory').modaal({
		type: 'inline',
		background: $modalBgColor,
		overlay_opacity: $modalBgOpacity,
		animation_speed: 400,
		content_source: $('.js-modal-jobDetail-help--dormitory').attr('data-modal-source'),
	});

	// お仕事詳細 > ヘルプ : 雇用形態
	$('.js-modal-jobDetail-help--employmentStatus').modaal({
		type: 'inline',
		background: $modalBgColor,
		overlay_opacity: $modalBgOpacity,
		animation_speed: 400,
		content_source: $('.js-modal-jobDetail-help--employmentStatus').attr('data-modal-source'),
	});

  // 登録会予約 > WEB面接 > ヘルプ
	$('.js-openModal--webmen').modaal({
		type: 'inline',
		background: $modalBgColor,
		overlay_opacity: $modalBgOpacity,
		animation_speed: 400,
		content_source: $('.js-openModal--webmen').attr('data-modal-source'),
		//start_open: true,
	});

	// 登録会予約 > マップ
	$(document).on('click', '.js-modal-apply--map', function(event) {
		event.preventDefault();
		const venueId = $(this).attr('data-venue');
		const url = `/venue/detail/${venueId}`;
		const wrapHtml = `
		<div class="c-modal c-modal-aplly-map" id="modal-aplly-map">
		  <div class="c-modal-aplly-map__container">
		    <iframe src="${url}" class="c-modal-aplly-map__iframe" frameborder="0"></iframe>
		    <button class="c-modal-button__closeBtn">モーダルウィンドウを閉じる</button>
		  </div>
		</div>`;

	  $(this).modaal({
      start_open: true,
      type: 'inline',
      background: $modalBgColor,
      overlay_opacity: $modalBgOpacity,
      animation_speed: 400,
      content_source: '<div>' + wrapHtml + '</div>',
      hide_close: true,
    })
	});

	// 登録会予約 > 完了画面 > 会員登録サジェスト
	$('.js-modal-apply-registrationSuggest').each(function(_index, elem) {
		$(elem).modaal({
			type: 'inline',
			background: $modalBgColor,
			overlay_opacity: $modalBgOpacity,
			animation_speed: 400,
			content_source: $(elem).attr('data-modal-source'),
			//hide_close: true,
			//start_open: true,
		});
	});

	// エントリーシート > 卒業年早見表
	$('.js-modal-graduatedYear').modaal({
		type: 'inline',
		background: $modalBgColor,
		overlay_opacity: $modalBgOpacity,
		animation_speed: 400,
		content_source: $('.js-modal-graduatedYear').attr('data-modal-source'),
		hide_close: true,
		before_open : function(){
			esGraduatedYearCalc();
		}
	});

	// エントリーシート > 資格選択
	var qualificationVMInstance
	$('.js-modal-certification').modaal({
		type: 'inline',
		background: $modalBgColor,
		overlay_opacity: $modalBgOpacity,
		animation_speed: 400,
		//start_open: true,
		content_source: $('.js-modal-certification').attr('data-modal-source'),
		hide_close: true,
		before_open: function() {
			if (!qualificationVMInstance) {
				qualificationVMInstance = qualificatonSearchVM();
			} else {
				qualificationVMInstance.reset();
			}
		}
	});

	// エントリーシート > 勤務地を選択する
	$('.js-modal-entrysheet--workingPlace').modaal({
		type: 'inline',
		background: $modalBgColor,
		overlay_opacity: $modalBgOpacity,
		animation_speed: 400,
		//start_open: true,
		content_source: $('.js-modal-entrysheet--workingPlace').attr('data-modal-source'),
		hide_close: true,
		after_open: function() {
			esSelectWorkingPlace();
		}
	});

	// マイページ > 登録会の変更・キャンセル
	$('.js-modal-meetingScheduleChange').modaal({
		type: 'inline',
		background: $modalBgColor,
		overlay_opacity: $modalBgOpacity,
		animation_speed: 400,
		//start_open: true,
		content_source: $('.js-modal-meetingScheduleChange').attr('data-modal-source'),
		hide_close: true,
		after_open : function(){
			meetingSheduleChangeSetData();
		}
	});

	// マイページ > 退会
	$('.js-modal-leave').modaal({
		type: 'inline',
		background: $modalBgColor,
		overlay_opacity: $modalBgOpacity,
		animation_speed: 400,
		//start_open: true,
		content_source: $('.js-modal-leave').attr('data-modal-source'),
		hide_close: true,
  });

	// マイページ > お気に入り削除
	$('#js-favoriteDeleteButton').on('modal.open', function(event) {
		$('#js-favoriteDeleteButton').modaal({
			type: 'inline',
			background: $modalBgColor,
			overlay_opacity: $modalBgOpacity,
			animation_speed: 400,
			//start_open: true,
			content_source: $('#js-favoriteDeleteButton').attr('data-modal-source'),
			hide_close: true,
			start_open: true,
			should_open: function() {
				return $(event.target).data('modal-allow')
			},
			after_open: function($el) {
				// for IE11
				$el.find('button[form]').off('click.deleteFavorite').on('click.deleteFavorite', function(event) {
					event.preventDefault();
					$('#' + $(event.target).attr('form')).submit();
				});
			}
		});
  });
  

  // モーダル > 閉じるボタン
	$(document).on('click', '.c-modal-button__closeBtn, .js-modal-close', function(e){
    e.preventDefault();
    const id = $(this).parents('.modaal-wrapper').attr('id');
    $(`[data-modaal-scope=${id}]`).modaal('close');
	});

});