$(function (){
	function jobDetail_heaerThumbs_slider () {
		$('.c-jobDetail__headerThumbs').not('.slick-initialized').slick({
			dots: false,
			arrows: false,
			appendArrows: $('.c-job-images .c-slider__arrows'),
			prevArrow: '<li class="c-slider__arrowLeft"><img src="/assets/images/btn-slider-prev.svg" alt="一つ前の画像"></li>',
			nextArrow: '<li class="c-slider__arrowRight"><img src="/assets/images/btn-slider-next.svg" alt="一つ後の画像"></li>',
			infinite: true,
			autoplay: true,
			autoplaySpeed: 4000,
			speed: 400
		});
	}
	jobDetail_heaerThumbs_slider();
});