import { modal_checkboxAll_monitoring } from '../jobSearch/search-modal-checkboxAll-monitoring';

$(function(){

  // イベントバブリングを抑制
  $('.c-modal-select-lv1__heading *').on('click', function(e){
    e.stopPropagation();
  });

  // モーダル > 一括チェック
	$(document).on('change', '.c-modal-select-lv1__heading .c-checkbox__input', function(){
		var $checked = $(this).prop('checked');
		var $target = $(this).parents('.c-modal-select-lv1__item').find('.c-modal-select-lv2__item .c-checkbox__input');
		if ( $checked ) {
      $target.prop('checked', true);
      if ($(this).closest('.js-accordion--wrapper')) {
        var $accordion_btn = $(this).closest('.js-accordion--wrapper').find('.js-accordion--btn'),
            $accordion_target = $(this).closest('.js-accordion--wrapper').find('.js-accordion--target');
        // アコーディオンを開く
        if (!$accordion_btn.hasClass('is-opened')) {
          $accordion_target.slideDown();
        }
      }
		} else {
			$target.prop('checked', '');
		}
  });

  // モーダル > 一括チェックの逆監視
	$(document).on('change', '.c-modal-select-lv2__item .c-checkbox__input', function(){
    modal_checkboxAll_monitoring($(this));
  });

});