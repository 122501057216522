$(function(){
	// お仕事詳細の場合だけ処理
	if ($('.c-jobDetail-btns')[0]) {
		// フッターに paddig-bottom を追加
		$('.c-footer-logoArea').addClass('is-additionPadding');
		// エントリーボタンエリアを、body直下に複製
		$('.c-jobDetail-btns').clone(true).addClass('is-cloned').appendTo('body');
		// オリジナルのボタンエリアが見切れたら、複製したエリアを表示する関数
		function check_scrollTop () {
			var $btnOriginal_offsetTop = $('.c-jobDetail-btns:not(.is-cloned)').offset().top + 130;
			//console.log($btnOriginal_offsetTop);
			if ( $btnOriginal_offsetTop < $(window).scrollTop() ) {
				$('.c-jobDetail-btns.is-cloned').addClass('is-visible');
        // ChatBot の位置調整
        if ($('.js-aiChat')[0]) {
          $('.js-aiChat').addClass('is-visibleJobDetailBtns');
        }
        if ($('.js-historyBtnArea')[0]) {
          $('.js-historyBtnArea').addClass('is-visibleJobDetailBtns');
        }
			} else {
				$('.c-jobDetail-btns.is-cloned').removeClass('is-visible');
        // ChatBot の位置調整
        if ($('.js-aiChat')[0]) {
          $('.js-aiChat').removeClass('is-visibleJobDetailBtns');
        }
        if ($('.js-historyBtnArea')[0]) {
          $('.js-historyBtnArea').removeClass('is-visibleJobDetailBtns');
        }
			}
		}
    if ($('.js-aiChat')[0]) {
      $('.js-aiChat').addClass('is-jobDetail');
    }
		var timerID = ""; // 間引き処理用のタイマー設定
		$(window).on('scroll', function(){
			if (window.innerWidth >= 768 && timerID === "") {
				timerID = setTimeout(function() {
					check_scrollTop();
					timerID = "";
				}, 300);
			}
		});
	}
});