// 適正テストのリンクをクリックしたら、不活性化
$(function(){
  if( $('.js-aptitudeTest-link')[0] ) {
    $('.js-aptitudeTest-link').on('click', function (){
      var $TimerID = setTimeout( function(){
        $('.js-aptitudeTest-link').addClass('is-disabled').prop('disabled', true);
        clearTimeout($TimerID);
      }, 50);
    });
  }
})