import { CountUp } from './vendor/countUp.min';
export function search_resultCount (){
  $(function() {
    if ($('#js-jobSearchForm')[0]) {
      var $form = $('#js-jobSearchForm'),
          $count = parseInt($('#js-jobSearchResultNumber').text().replace(/,/g, '')),
          $countTemp = null,
          $countUpOptions = {
            startVal: 0,
            duration: 0.7,
          };

      // 検索条件からヒットする案件数を取得
      function getCount() {
        // 検索条件からヒットする案件数を取得
        $.ajax({
          url: '/search/result',
          data: $form.serialize(),
          method: 'GET',
          timeout: 1500,
          dataType: 'json',
        }).then(function(count) {
          $countTemp = count;
        }).catch(function(err) {
          // nothind todo
        });
        // ヒット数をカウントアップしながら反映
        var timer = setInterval( function() {
          if ($countTemp !== null && $count != $countTemp) {
            clearInterval(timer);
            $count = $countTemp;
            var demo = new CountUp('js-jobSearchResultNumber', $count, $countUpOptions);
            if (!demo.error) {
              demo.start();
            } else {
              console.error(demo.error);
            }
          }
        }, 100);
      }

      // 初期実行 (モーダルのために必要)
      getCount();
      // フォームパーツが操作されたら実行（モーダルからの操作には反応しない）
      // モーダルからの反映は、 /modal/modal-open.js に記述
      $form.on('change', 'input, select', function(){
        getCount();
      });

    }
  });
}