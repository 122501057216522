$(function(){
  if ($('.js-searchcond-area')[0]){
    // 選択状態を復元
    function restoreCheckedObj ($checkedVal, $label, $target) {
      if ($checkedVal) {
        // 隠しinputの値を配列に代入
        var $array_checeked = $checkedVal.split(","),
            $text = null;
        // 配列の値ごとに、対応するチェックボックスを探す
        for (var i=0; i<$array_checeked.length; i++) {
          $($label).each(function(){
            if ($(this).val() === $array_checeked[i]) {
              // エリア指定（地方版）のときは、テキストの頭に都道府県名を入れる
              var $textTemp = $(this).next('.c-checkbox__label').text();
              if ($(this).parents('.js-selectArea-local').length){
                $textTemp = $(this).parents('.js-selectArea-local').prev('.c-modal-select-lv1__heading').find('.c-checkbox__label').text() + '-' + $textTemp;
              }
              // テキストを生成
              if (!$text) { // ＄textが nullの場合（= 最初のヒット）
                $text = $textTemp;
              } else { // ＄textが null以外（= 2番目以降のヒット）
                $text = $text + '/' + $textTemp;
              }
            }
          })
        }
        // 生成したテキストを代入
        // 業種・職種のみ特別処理
        if ($label === '.js-occupation') {
          // 元のテキストを格納
          var $tempText = $($target).text();
          if ($tempText != '-') {
            // 元のテキストが存在していれば合体して代入
            $($target).text($tempText + '/' + $text);
          } else {
            // 元のテキストが存在しなければ、そのまま代入
            $($target).text($text);
          }
        } else {
          $($target).text($text);
        }
      }
    }
    restoreCheckedObj($('.js-searchcond-area').val(), '.js-area', '.c-searchCond-condTable__text.is-area');
    restoreCheckedObj($('.js-searchcond-industryType').val(), '.js-industryType', '.c-searchCond-condTable__text.is-industryType');
    restoreCheckedObj($('.js-searchcond-occupation').val(), '.js-occupation', '.c-searchCond-condTable__text.is-industryType');
    restoreCheckedObj($('.js-searchcond-cond').val(), '.js-cond', '.c-searchCond-condTable__text.is-cond');
  }
});