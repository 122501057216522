$(function() {
  if ( $('.js-scrollable')[0] ) {
    $('.js-scrollable').each(function(){

      new ScrollHint($(this));

      var $scrollable = $(this),
          $scrollHintIcon = $(this).find('.scroll-hint-icon'),
          $timerID = null;
      
      function adjustScrollHint (){
        var $winHeight = window.innerHeight,
            $scrollable_height = $scrollable.height(),
            $scrollable_offsetTop = $scrollable.offset().top,
            $scrollTop = $(window).scrollTop();

        if ($scrollable_height >= $winHeight) {
          $scrollHintIcon.addClass('is-largeTable');
        }

        if ($scrollable_offsetTop > $scrollTop) {
          $scrollHintIcon.removeClass('is-fixed is-passedBy');
        } else if ($scrollable_offsetTop <= $scrollTop && $scrollable_offsetTop + $scrollable_height - $winHeight > $scrollTop) {
          $scrollHintIcon.addClass('is-fixed').removeClass('is-passedBy');
        } else if ($scrollable_offsetTop + $scrollable_height - $winHeight <= $scrollTop) {
          $scrollHintIcon.removeClass('is-fixed').addClass('is-passedBy');
        }
      }

      // 初期実行
      adjustScrollHint ()
      // スクロール時とウィンドウリサイズ時に処理
      $(window).on('scroll resize', function(){
        if (!$timerID) {
          adjustScrollHint ();
          $timerID = setTimeout(function(){
            clearTimeout ($timerID);
            $timerID = null;
          }, 1000/60);
        }
      });

    });

  }
});