export function searchCond_area_nearHome (){
  if ($('#searchCond-nearHome')[0]){

    var $checkBox = $('#searchCond-nearHome'),
        $searchCondArea = $('#searchCond-area'),
        $searchCondNearHome = $('#searchCond-nearHomeSelect');

    $('.c-modal-nearHome-select select').prop('disabled', true);

    function search_cond_nearHome_text (){
      var $target = $searchCondNearHome.find('select');
      var $targetText = $('#searchCond-nearHomeSelect option[value="' + $target.val() + '"]').text();
      $('.c-searchCond-condTable__text.is-nearHome').text('自宅近くからさがす/' + $targetText).removeClass('is-hidden');
      $('.c-searchCond-condTable__text.is-area').addClass('is-hidden');
    }

    function search_cond_nearHome (){
      if ($checkBox.prop('checked') || $('.c-modal-tabTarget.is-nearHome').is(':visible')) {
        // 通常のエリア指定を不活性化
        $searchCondArea.addClass('is-disabled');
        // エリア指定の隠しinputを不活性化
        $('.js-searchcond-area').prop('disabled', true);
        // 自宅の近くでさがす を活性化
        if (!$checkBox.is(':disabled')) {
          $searchCondNearHome.removeClass('is-disabled').find('select').prop('disabled', false);
        }
        // メモ : search-selectArea.js にも同様の処理アリ
        // SP表示のテキストへ反映
        search_cond_nearHome_text ();
      } else {
        // 通常のエリア指定を活性化
        $searchCondArea.removeClass('is-disabled');
        // エリア指定の隠しinputを活性化
        $('.js-searchcond-area').prop('disabled', false);
        // 自宅の近くでさがす を不活性化
        $searchCondNearHome.addClass('is-disabled').find('select').prop('disabled', true);
        $('.c-searchCond-condTable__text.is-nearHome').addClass('is-hidden');
        $('.c-searchCond-condTable__text.is-area').removeClass('is-hidden');
      }
    }
    search_cond_nearHome ();

    // チェックボックス 変更時の処理
    $checkBox.on('change', function(){
      search_cond_nearHome ();
    });
    // タブ変更時
    $('.c-modal-tabNav__anchor[data-target="is-selectArea"],.c-modal-tabNav__anchor[data-target="is-nearHome"]').on('tab.change', function() {
      search_cond_nearHome();
    });
    // モーダルが開かれたとき
    $('.js-modal-searchCond--area').on('modal.opened', function() {
      search_cond_nearHome();
    });

    // セレクトボックスを変更したときの処理
    $searchCondNearHome.find('select').on('change', function(){
      search_cond_nearHome_text ();
    })


    // SP/PC表示変更時のクリア処理
    function viewChange (){
      // PC→SPに変形
      if ( window.innerWidth < 768 ) {
        // 条件表示エリアの不活性化を解除
        $('#searchCond-area').removeClass('is-disabled');
      // SP→PCに変形
      } else {
        search_cond_nearHome ();
      }
    }
    viewChange();

    $(window).on('resize', function(){
      viewChange();
    })

  }
}