$(function(){
	if ($('.c-select__placeholder')[0]) {

    function check_selectVal ($target) {
      var $select = $target.closest('select');
      if ($select.val() === "") {
        $select.addClass('is-placeholder')
      } else {
        $select.removeClass('is-placeholder')
      }
    }

    // 初期処理
    $('.c-select__placeholder').each(function(){
      if ($(this).text() === 'ご選択ください') {
        var $target = $(this);
        check_selectVal($target);
      }
    });

    /*
    $(document).on('change.selectPlaceholder', '.is-placeholder', function(){
      console.log('hit');
      var $target = $(this);
      check_selectVal($target);
    });
    */

  }
});