$(function (){
  if ($('.js-accordion--trigger')[0]) {

    // 初期セット : アコーディオンの対象を非表示
    $('.js-accordion--target').each(function (){
      if( !$(this).closest('.js-accordion--wrapper').hasClass('is-opened') ) {
        $(this).hide();
      }
    });

    // アコーディオンの開閉
    $('.js-accordion--trigger').on('click', function(e){
      // デフォルトの挙動をキャンセル
      e.preventDefault();

      var $trigger = $(this),
          $wrapper = $trigger.closest('.js-accordion--wrapper'),
          $target  = $wrapper.find('.js-accordion--target');

      if (!$wrapper.hasClass('is-opened')) {
        $wrapper.addClass('is-opened');
        $target.slideDown();
      } else {
        $wrapper.removeClass('is-opened');
        $target.slideUp();
      }
    });

  }
});