$(function() {
  /* ヘッダー > グロナビの展開
  ---------------------------------------- */
  var ScrollPositionX, ScrollPositionY;
  // スクロール停止
  function _no_scroll() {
    ScrollPositionY = $(window).scrollTop();
    ScrollPositionX = $(window).scrollLeft();
    $('body').addClass('noscroll').css({'top': (-ScrollPositionY), 'left': (-ScrollPositionX)});
  }
  // スクロール復活
  function _return_scroll() {
    $('body').removeClass('noscroll').css({'top': 'auto', 'left': 'auto'});
    $(window).scrollTop(ScrollPositionY);
  }
  // グロナビ : 展開
  $('.c-nav-drawer').on('click', function(){
    _no_scroll(); // スクロールをロック
    $('.c-nav__container').height(window.innerHeight - 60); // ナビの高さを指定
    $('.c-nav__container').fadeIn().css('display', 'flex'); // ナビを表示
    $('.js-aiChat').hide();
  });
  // グロナビ : たたむ
  $('.c-nav-btnClose').on('click', function(){
    _return_scroll();
    $('.c-nav__container').fadeOut();
    $('.js-aiChat').show();
  });
});