// 登録会予約 > 日付から選ぶ > 会場 > 時刻を選択すると「予約する」ボタンが活性化
$(function(){
	$(document).on('change', '.js-apply-date-selectTime', function(){
		// select要素の value が取得可能 = プレースホルダー用ではない ときに処理
		if ($(this).val()) {
			// 操作されたselect要素の直後のボタンを活性化
			$(this).parent('.c-select').next('.c-apply-place-item__btn').removeClass('is-disabled');
		}
	});
});
