$(function (){
  // open
	$('.js-search-fixed-area-open').on('click', function(e){
    if($(this).hasClass('is-open')){
      $(this).closest('.js-search-fixed-area').removeClass('is-open');
      $(this).removeClass('is-open');
    } else {
      $(this).closest('.js-search-fixed-area').addClass('is-open');
      $(this).addClass('is-open');
      e.preventDefault();
    }
	});
  // close
	$('.js-search-fixed-area-close').on('click', function(e){
    $(this).closest('.js-search-fixed-area').removeClass('is-open');
    $(this).next('.js-search-fixed-area-open').removeClass('is-open');
    e.preventDefault();
	});
});