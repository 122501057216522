$(function (){
	function jobDetail_image_slider () {
		$('.c-banner__container').not('.slick-initialized').slick({
			dots: true,
			appendDots: $('.c-banner .c-slider__dots'),
			arrows: false,
			appendArrows: $('.c-job-images .c-slider__arrows'),
			prevArrow: '<li class="c-slider__arrowLeft"><img src="/assets/images/btn-slider-prev.svg" alt="一つ前の画像"></li>',
			nextArrow: '<li class="c-slider__arrowRight"><img src="/assets/images/btn-slider-next.svg" alt="一つ後の画像"></li>',
			infinite: true,
			autoplay: true,
			autoplaySpeed: 3000,
      speed: 400,
      cssEase: 'ease-out',
			centerMode: true,
			variableWidth: true,
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 767,
					settings: {
					}
				}
			]
		});
	}
	jobDetail_image_slider();
});