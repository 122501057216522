import { modal_checkboxAll_monitoring } from '../jobSearch/search-modal-checkboxAll-monitoring';

export function jobSearch_modal_select ($label, $modal, $targetInput) {

  // 選択状態を復元
  // メモ : 「閉じる」ボタンで確定しなかった場合のために、モーダルを開くごとに処理
  function restoreCheckedObj ($checkedVal) {
    if ($checkedVal) {
      // 隠しinputの値を配列に代入
      var $array_checeked = $checkedVal.split(",");
      for (var i=0; i<$array_checeked.length; i++) {
        $($label).each(function(){
          if ($(this).val() === $array_checeked[i]) {
            $(this).prop('checked', true);
          }
        })
      }
    }
  }
  restoreCheckedObj($($targetInput).val());
  // モーダル > 一括チェックの状態復帰
	$('.c-modal-select-lv1__heading .c-checkbox__input').each(function(){
    modal_checkboxAll_monitoring($(this));
  });

  // 「選択する」ボタンで反映処理
	$('.c-modal .c-modal-button__btn').off('click.select'+$label).on('click.select'+$label, function(){
		var $checked = $('.c-modal ' + $label + ':checked');
    var $chekedObj = null,
        $chekedObjVal = null,
				$modalScope = $(this).parents('.modaal-wrapper').attr('id'), //開いているモーダルと対になる開くボタンを特定するキーを取得
				$target = $('[data-modaal-scope="' + $modalScope + '"]');
    // チェックされた項目のラベルを"/"区切りで連結
    if ($checked[0]){
      $checked.each(function(){
        if (!$chekedObj) {
          $chekedObj = $(this).next().text();
          $chekedObjVal = $(this).val();
        } else {
          $chekedObj = $chekedObj + '/' + $(this).next().text();
          $chekedObjVal = $chekedObjVal + ',' + $(this).val();
        }
      });
    } else {
      $chekedObj = "-"
      $chekedObjVal = ""
    }
		// 選択を反映
    // 業種・職種のみ特別処理
    if(!$(this).hasClass('js-salarySelectBtn')){
      if ($label === '.js-occupation') {
        // 元のテキストを格納
        var $tempText = $target.next().text();
        if ($tempText != '-' && $chekedObj != "-") {
          // 業種のテキストが存在し、職種のテキストも存在していれば合体して代入
          $target.next().text($tempText + '/' + $chekedObj);
        } else if ($tempText != '-' && $chekedObj === "-") {
          // 業種のテキストが存在し、職種のテキストが"-"のときはそのまま
        } else {
          // 業種のテキストが存在しなければ、そのまま代入
          $target.next().text($chekedObj);
        }
      } else {
        $target.next().text($chekedObj);
      }
    }
		$($targetInput).val($chekedObjVal);
		// モーダルを閉じる
		$($modal).modaal('close');
	});

};