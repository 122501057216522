// エントリーシート作成 > 職歴・資格 のラジオボタンで入力ブロック表示を制御
$(function(){

  // 就業経験入力欄の制御
  function activate_businessEx_input () {
    var numOfCompanies = Number($('.js-es-businessEx-NumOfCompanies').val());
    // 活性化処理
    for (var i=1; i <= numOfCompanies; i++){
      $('.js-businessEx-container .c-entrysheet-repaetBlk:nth-of-type(' + i + ')')
        .removeClass('is-hidden').fadeIn(400)
        .find('input, select, textarea').prop('disabled', false)
        .end()
        .find('.js-ftra-requiredFlag').prop('checked', true);
    }
    // 不活性化処理
    for (var j = numOfCompanies + 1; j <= 7; j++){
      $('.js-businessEx-container .c-entrysheet-repaetBlk:nth-of-type(' + j + ')')
        .addClass('is-hidden').fadeOut(400)
        .find('input, select, textarea').prop('disabled', true)
        .end()
        .find('textarea, input[type=text], select').val('')
        .end()
        .find(':checked').prop('checked', false)
        .end()
        .find('.js-ftra-requiredFlag').prop('checked', false)
    }
  }

  // 職歴
	$('.js-es-checkRadio--businessEx').on('change', function(){
    var $target = $('.js-businessEx-container');
		if ($(this).attr('data-value') === "on") {
      $target.find('.js-es-businessEx-NumOfCompanies').prop('disabled', false);
      activate_businessEx_input();
      $target.fadeIn(400);
		} else {
      $target.find('.js-es-businessEx-NumOfCompanies').prop('disabled', true);
      $target.find('.c-entrysheet-repaetBlk .js-ftra-requiredFlag').prop('checked', false);
      $target.find('.c-entrysheet-repaetBlk:not(.is-hidden)').find('input, select, textarea').prop('disabled', true);
      $target.fadeOut(400);
    }
    // エフトラEFO発火のためにフォーカスを移動
    $('.dummyFocusForFtra1')[0].focus();
    $(this)[0].focus();
  });

  // 今までの経験社数で、表示ブロック数を制御
  $('.js-es-businessEx-NumOfCompanies').on('change', function(){
    activate_businessEx_input();
    // エフトラEFO発火のためにフォーカスを移動
    $('.dummyFocusForFtra2')[0].focus();
    $(this)[0].focus();
  });

  // 職歴の処理ここまで --- //



  // 資格
	$('.js-es-checkRadio--certification').on('change', function(){
		var $target = $('.js-certification-container');
		if ($(this).attr('data-value') === "on") {
			$target.find('.c-entrysheet-repaetBlk:not(.is-hidden)').find('input, select, textarea').prop('disabled', false);
			$target.fadeIn(400);
		} else {
			$target.find('.c-entrysheet-repaetBlk:not(.is-hidden)').find('input, select, textarea').prop('disabled', true);
			$target.fadeOut(400);
		}
  });

  $('.js-certification-container [type="checkbox"]').on('change', function(){
    var isChecked = $(this).is(':checked')
    var $selectElems = $(this).closest('.is-date').find('select')
    var $wrapElems = $selectElems.closest('.c-select')
    $selectElems.prop('disabled', isChecked)
    if (isChecked) {
      $wrapElems.addClass('is-disabled')
    } else {
      $wrapElems.removeClass('is-disabled')
    }
  });
});