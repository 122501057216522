$(function() {
  if ($('.js-checkAll')[0]) {
    var $checkAll = $('.js-checkAll'),
        $checkAllTarget = $($checkAll.attr('data-checkAllTarget')),
        $checedFlag = [];

    function moniterInputStatus (){
      $checedFlag = [];
      // 対象のinput要素のチェック状況を配列に格納
      $checkAllTarget.each(function() {
        if ( $(this).prop('checked') ) {
          $checedFlag.push('checked');
        } else {
          $checedFlag.push('unchecked');
        }
      });
      // チェックされていないものが一つでもあれば、一括チェックのチェックを外す
      if ($checedFlag.indexOf('unchecked') > -1) {
        $checkAll.prop('checked', false);
      } else {
        $checkAll.prop('checked', true);
      }
    }

    $checkAllTarget.on('change', function(){
      moniterInputStatus();
    });

  }
});