$(function (){
	function recommendedWork_slider () {
		$('.js-recommendedWork-slider').not('.slick-initialized').slick({
			dots: true,
			appendDots: $('.c-recommendedWork .c-slider__dots'),
			arrows: true,
			appendArrows: $('.c-recommendedWork .c-slider__arrows'),
			prevArrow: '<li class="c-slider__arrowLeft"><img src="/assets/images/btn-slider-prev.svg" alt="一つ前"></li>',
			nextArrow: '<li class="c-slider__arrowRight"><img src="/assets/images/btn-slider-next.svg" alt="一つ後"></li>',
			infinite: true,
			//autoplay: true,
			speed: 400,
			centerMode: true,
			centerPadding: '40px',
			variableWidth: true,
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 767,
					settings: 'unslick',
				}
			]
		});
	}
	recommendedWork_slider();

  // 画面幅の変更に応じてスライダーを調整
  var $winWidth = window.innerWidth;
	$(window).on('scroll', function(){
		$winWidthTemp = window.innerWidth;
		if ($winWidth != $winWidthTemp) {
			recommendedWork_slider();
			$winWidth = $winWidthTemp;
		}
	});
});