export function mordal_accordion (){
  if ($('.js-accordion--btn')[0]) {

    // 下層にチェック済みの項目があれば展開
    $('.js-accordion--target').each(function(){
      if ( $(this).find(':checked').length ) {
        $(this).show();
        $(this).closest('.js-accordion--wrapper').find('.js-accordion--btn').addClass('is-opened');
      } else {
        // デフォルトでアコーディオンを閉じる
        $(this).hide();
      }
    });

    // アコーディオンの開閉 > 開く
    $('.js-accordion--wrapper').off('click.open.accordion').on('click.open.accordion', '.js-accordion--btn:not(.is-opened)', function(e){
      e.stopPropagation();
      $(this).closest('.js-accordion--wrapper').find('.js-accordion--target').slideDown();
      $(this).addClass('is-opened');
    });
    // アコーディオンの開閉 > 閉じる
    $('.js-accordion--wrapper').off('click.close.accordion').on('click.close.accordion', '.js-accordion--btn.is-opened', function(e){
      e.stopPropagation();
      $(this).closest('.js-accordion--wrapper').find('.js-accordion--target').slideUp();
      $(this).removeClass('is-opened');
    });
  }
}