// ログインページ > メアド・パスワード入力でログインボタンを活性化
$(function(){
  if($('.js-login-inputCheck')[0]) {
    var $submit = $('.js-login-inputCheck-submit');
    // 初期設定 : ログインボタンを不活性化
    // 初期状態で入力されていれば不活性化しない
    if ( !$('.js-login-inputCheck-input').eq(0).val() ||  !$('.js-login-inputCheck-input').eq(1).val() ) {
      $submit.prop('disabled', true).addClass('is-disabled');
    }
    // 入力欄の更新をチェックしてログインボタンを活性化
    $('.js-login-inputCheck-input').on('input', function(){
      var $inputFlag = 'ok';
      $('.js-login-inputCheck-input').each(function(){
        if (!$(this).val()) {
          $inputFlag = 'empty'
        }
      });
      if ($inputFlag === 'ok') {
        $submit.prop('disabled', false).removeClass('is-disabled');
      } else if ($inputFlag === 'empty') {
        $submit.prop('disabled', true).addClass('is-disabled');
      }
    });
  }
});