export function es_datepicker () {
	if ($('.js-datepicker')[0]) {
		var $today = new Date(),
				$date	 = null;
		function enablePicker () {
			var	picker = new Pikaday(
				{
						field: $('.js-datepicker')[0],
						//trigger: $('.c-apply-input__row.is-date select'),
						trigger: $('.js-datepicker:not(.is-disabled)'),
						showMonthAfterYear: true,
						yearSuffix: '年',
						i18n: {
							previousMonth : '前月',
							nextMonth     : '次月',
							months        : ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
							weekdays      : ['日曜','月曜','火曜','水曜','木曜','金曜','土曜'],
							weekdaysShort : ['日','月','火','水','木','金','土'],
						},
						firstDay: 0,
						minDate: $today,
						//maxDate: new Date($today.getFullYear() + 1, $today.getMonth(), 31),
						format: 'YYYY/M/D',
				}
			);
		}
		enablePicker();

    // ラジオボタンの値によって、datepickerの操作を制御
		$('.js-PossibleDateOfStartingWork').on('change', function(){
      var $val = $(this).attr('data-value');
      switch ($val) {
        case "期日指定" :
          $('.c-apply-input__datePicker').removeClass('is-disabled');
          $('.js-datepicker').prop('disabled', false).focus();
          break;
        case "即日" :
        default :
          $('.c-apply-input__datePicker').addClass('is-disabled');
          $('.js-datepicker').prop('disabled', true);
          break;
      }
		});
	}
}