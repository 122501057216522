$(function (){
	function history_slider () {
		if($('.c-historyList__item').length >= 5) {
			$('.js-history-slider').not('.slick-initialized').slick({
				dots: true,
				appendDots: $('.c-historyList .c-slider__dots'),
				arrows: true,
				appendArrows: $('.c-historyList .c-slider__arrows'),
				prevArrow: '<li class="c-slider__arrowLeft"><img src="/assets/images/btn-slider-prev.svg" alt="一つ前"></li>',
				nextArrow: '<li class="c-slider__arrowRight"><img src="/assets/images/btn-slider-next.svg" alt="一つ後"></li>',
				slidesToShow: 4,
				slidesToScroll: 1,
				infinite: false,
				speed: 400,
				responsive: [
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 1,
							centerMode: true,
							centerPadding: '40px',
							variableWidth: true,
						}
					}
				]
			});
		} else if($('.c-historyList__item').length >= 1) {
			$('.js-history-slider').not('.slick-initialized').slick({
				dots: true,
				appendDots: $('.c-historyList .c-slider__dots'),
				arrows: true,
				appendArrows: $('.c-historyList .c-slider__arrows'),
				prevArrow: '<li class="c-slider__arrowLeft"><img src="/assets/images/btn-slider-prev.svg" alt="一つ前"></li>',
				nextArrow: '<li class="c-slider__arrowRight"><img src="/assets/images/btn-slider-next.svg" alt="一つ後"></li>',
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: false,
				speed: 400,
				centerMode: true,
				centerPadding: '40px',
				variableWidth: true,
				mobileFirst: true,
				responsive: [
					{
						breakpoint: 768,
						settings: 'unslick',
					}
				]
			});
		}
	}
	history_slider();

  // 画面幅の変更に応じてスライダーを調整
  var $winWidth = window.innerWidth;
	$(window).on('scroll', function(){
		$winWidthTemp = window.innerWidth;
		if ($winWidth != $winWidthTemp) {
			history_slider();
			$winWidth = $winWidthTemp;
		}
	});

	// 閉じるボタン
	$('.js-history-close').on('click', function (e) {
		e.preventDefault();
		$('.js-history-area').removeClass('is-open');
	});

	// 開くボタン
	$('.js-history-open').on('click', function (e) {
		e.preventDefault();
		$('.js-history-area').addClass('is-open');
	});
});