// パスワード入力 > 文字列を見える化
$(function(){
	$('.c-inputText__revealPassword').on('click', function(){
		var $teargetInput = $(this).prev('input');
		var $teargetInputType = $teargetInput.attr('type');
		if ($teargetInputType === "password") {
			$teargetInput.attr('type', 'text');
		} else if ($teargetInputType === "text") {
			$teargetInput.attr('type', 'password');
		}
	})
});