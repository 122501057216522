/** エントリーシート > 資格選択 */
export function qualificatonSearchVM() {
  return new Vue({
    el: '#app',
    data() {
      return {
        keywords: '',
        result: [],
        fuse: window.qualificatoinsFuseInstance,
        selectedCategoryIndex: 0,
        selected: null,
        showNoResult: false,
      };
    },
    computed: {
      resultLength() {
        return this.result.length.toLocaleString();
      },
      resultGroupBy() {
        return this.groupBy(this.result, function (_ref) {
          const item = _ref.item;
          return item.category.name;
        });
      },
      selectedCategoryQualifications() {
        return this.resultGroupBy[this.selectedCategoryIndex][1];
      }
    },
    methods: {
      reset() {
        this.keywords = '';
        this.result = [];
        this.selectedCategoryIndex = 0;
        this.selected = null;
        this.showNoResult = false;
      },
      search() {
        this.selectedCategoryIndex = 0;

        if (this.keywords === '') {
          this.result = [];
          this.showNoResult = false;
          return;
        }

        const value = moji(this.keywords).convert("ZStoHS") // 全角スペース->半角スペース
          .convert("ZEtoHE") // 全角英数->半角英数
          .convert("HKtoZK") // 半角カナ->全角カナ
          .convert("KKtoHG") // カタカナ->ひらがな
          .toString();
        this.result = this.fuse.search(value);
        this.showNoResult = this.result.length === 0;
      },
      groupBy(array, getKey) {
        return Array.from(array.reduce(function (map, cur) {
          const key = getKey(cur);
          const list = map.get(key);

          if (list) {
            list.push(cur);
          } else {
            map.set(key, [cur]);
          }

          return map;
        }, new Map()));
      },
      selectDone() {
        if (this.selected) {
           //開いているモーダルと対になる開くボタンを特定するキーを取得
          const $modalScope = $(this.$el).parents('.modaal-wrapper').attr('id');
          const $target = $('[data-modaal-scope="' + $modalScope + '"]').parents('.c-entrysheet-certification__search');
          $target.nextAll('.c-entrysheet-certification__name').text(this.selected.name);
          $target.nextAll('.c-entrysheet-certification__name-input').val(this.selected.id).focus();
          // モーダルを閉じる
          $('.js-modal-certification').modaal('close');
        }
      }
    }
  });
}

