export function esGraduatedYearCalc () {
	// エントリーシート作成 > 卒業年早見表モーダル
	var $thisYear = new Date().getFullYear(),
			$graduate_year = null,
			$bd_year	= parseInt($('.js-birthday--year').text()),
			$bd_month = parseInt($('.js-birthday--month').text()),
			$bd_day		= parseInt($('.js-birthday--day').text());

  // 初期状態で「選択する」ボタンを不活性化
	//$('#modal-entrysheet-graduateYear .c-button__btn').addClass('is-disabled');

	// 年の選択肢を生成
	$('#modal-entrysheet-graduateYear .c-apply-input__col.is-year select').empty();
	for (var i = $bd_year; i <= $thisYear ; i++) {
		$('<option value="' + i + '">' + i + '年</option>').prependTo('#modal-entrysheet-graduateYear .c-apply-input__col.is-year select');
		if ( i == $thisYear) {
			$('#modal-entrysheet-graduateYear .c-apply-input__col.is-year option:first-child').prop('selected', true);
		}
	}

	// 早生まれの生年を前年に調整
	switch ($bd_month) {
		case 1:
		case 2:
		case 3:
			$bd_year = $bd_year - 1;
			break;
		case 4:
			if ($bd_day === 1) {
				$bd_year = $bd_year - 1;
			}
			break;
	}

  // 卒業年月日を更新
	$('#js-graduatedSchool').off('change.graduatedSchool').on('change.graduatedSchool', function(){
		// 生年+学歴から卒業年を計算
		switch ($(this).val()) {
			case '中学校':
				$graduate_year = $bd_year + 15 + 1;// +1は翌3月卒だから
				break;
			case '高等学校':
				$graduate_year = $bd_year + 18 + 1;
				break;
			case '短大 / 専門学校':
				$graduate_year = $bd_year + 20 + 1;
				break;
			case '大学':
				$graduate_year = $bd_year + 22 + 1;
				break;
		}
		// 卒業年をセレクトボックスに反映
		$('#modal-entrysheet-graduateYear .c-apply-input__col.is-year select').val($graduate_year);
	});
	// モーダル開き直したとき用
	$('#js-graduatedSchool').val('');

  // 「選択する」ボタンで反映処理
	$('#modal-entrysheet-graduateYear .c-button__btn').off('click.select.graduateYear').on('click.select.graduateYear', function(){
		// 学歴セレクトボックスを変更しているときのみ実行
		//if ( $('#js-graduatedSchool').val() ){
			var $graduate_year	= $('#modal-entrysheet-graduateYear .c-apply-input__col.is-year select').val(),
					$graduate_month = $('#modal-entrysheet-graduateYear .c-apply-input__col.is-month select').val(),
					$modalScope = $(this).parents('.modaal-wrapper').attr('id'), //開いているモーダルと対になる開くボタンを特定するキーを取得
					$target = $('[data-modaal-scope="' + $modalScope + '"]').parents('.c-graduationYear__btn');
			// 年を反映
			$target.prevAll('.c-apply-input__col.is-year').find('select').val($graduate_year);
			// 月を反映
			$target.prevAll('.c-apply-input__col.is-month').find('select').val($graduate_month).focus();
			// モーダルを閉じる
			$('.js-modal-graduatedYear').modaal('close');
		//}
	});
};