// ログインページ > メアド・パスワード入力でログインボタンを活性化
$(function(){
  if($('.js-check-input-birthday')[0]) {
    var $target = $('.js-check-input-birthday').find('select'),
        $submit = $('.js-check-input-birthday--submit'),
        $inputFlag = 'ok';
    // 選択状態をチェックする関数
    function checkVal () {
      $inputFlag = 'ok';
      $('.js-check-input-birthday select').each(function(){
        if (!$(this).val()) {
          $inputFlag = 'empty'
        }
      });
    }
    // 初期設定 : ログインボタンを不活性化
    if ( !$('.js-login-inputCheck-input').eq(0).val() ||  !$('.js-login-inputCheck-input').eq(1).val() ) {
      checkVal ();
      if ($inputFlag === 'empty') {
        $submit.prop('disabled', true).addClass('is-disabled');
      }
    }
    // 入力欄の更新をチェックしてログインボタンを活性化
    $('.js-check-input-birthday select').on('change', function(){
      checkVal ();
      if ($inputFlag === 'ok') {
        $submit.prop('disabled', false).removeClass('is-disabled');
      }
    });
  }
});