$(function (){
  // 初期セット
  function tab_default_set (){
    // 初期状態で「is-active」なタブがあるかチェック
    if ($('.c-searchCond-tabNav__item.is-active')[0]) {
      var $target = $('.c-searchCond-tabNav__item.is-active').eq(0).find('.c-searchCond-tabNav__anchor').attr('data-target');
      $('.c-searchCond-tabBody:not([id="' + $target + '"])').hide();
    } else {
      $('.c-searchCond-tabNav__item:first-of-type').addClass('is-active')
      $('.c-searchCond-tabBody:not(:nth-of-type(1))').hide();
    }
  }
  tab_default_set();

  // タブをクリックしたときの
	$('.c-searchCond-tabNav__item a').on('click', function(e){
		e.preventDefault();
		if ( !$(this).hasClass('is-active') ) {
			var $targetTab = $(this).attr('data-target');
			$('.c-searchCond-tabNav__item').removeClass('is-active');
			$(this).parents('.c-searchCond-tabNav__item').addClass('is-active');
			$('.c-searchCond-tabBody').hide();
			$('#' + $targetTab).fadeIn(400);
		}
	});
});