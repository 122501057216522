export function esSelectWorkingPlace () {
  if ($('.js-modal-es-workingPlace')[0]){
    //最大選択数4つのカウンター
    var checkedCount = 0;

    //開いているモーダルと対になる開くボタンを特定するキーを取得
    var $modalScope = $('#modal-entrysheet-workingPlace').closest('.modaal-wrapper').attr('id');
    var $target = $('[data-modaal-scope="' + $modalScope + '"]').parents('.c-entrysheet-workingPlace__search');

    if($('.js-es-workingPlace').val()) {
      $('.js-es-workingPlace').val().split(',').forEach(function(v) {
        $('.js-modal-es-wp-target[value="'+v+'"]').prop('checked', true);
        checkedCount++
      });
    }

    $('#modal-entrysheet-workingPlace .js-modal-es-workingPlace').off('change.prefCheck').on('change.prefCheck', function(){
      // 押したタイミングでチェックがついた時
      if($(this).prop('checked')) {
        checkedCount++
        if(checkedCount >= 4) {
          $('#modal-entrysheet-workingPlace .js-modal-es-workingPlace:not(:checked)').each(function(){
            $(this).prop('disabled', true).next().addClass('disabled');
          })
        }
      // 押したタイミングでチェックが外れた時
      } else {
        checkedCount--
        //最大の4から1つ減った場合、disabledを解除
        if(checkedCount === 3) {
          $('#modal-entrysheet-workingPlace .js-modal-es-workingPlace:disabled').each(function(){
            $(this).prop('disabled', false).next().removeClass('disabled');
          })
        }
      }

    })

    // 「選択する」ボタンで反映処理
    $('#modal-entrysheet-workingPlace .c-button__btn').off('click.select.esWorkingPlace').on('click.select.esWorkingPlace', function(){
      // 反映の対象 : class属性に js-modal-es-wp-target を持つチェックボックス
      var $checked = $('.js-modal-es-wp-target:checked'),
          $chekedPref = '',
          $chekedPrefValue = '';
      if ($checked.length) {
        // チェックされた県名を"/"区切りで連結
        $checked.each(function(){
          if (!$chekedPref) {
            $chekedPref = $(this).next().text();
            $chekedPrefValue = $(this).val();
          } else {
            $chekedPref = $chekedPref + '/' + $(this).next().text();
            $chekedPrefValue = $chekedPrefValue + ',' + $(this).val();
          }
        });
      } else {
        $chekedPref = '-';
        $chekedPrefValue = '';
      }
      // 選択したエリアをテキストで反映
      $target.next().text($chekedPref);
      // 選択したエリアをinput要素に反映（仮）
      $('.js-es-workingPlace').val($chekedPrefValue);
      // モーダルを閉じる
      $('.js-modal-entrysheet--workingPlace').modaal('close');
    });
  }
};