$(function(){
  $('.js-job-favoritBtn:not([data-modal-source]):not(.is-added)').on('click', function(event) {
    event.preventDefault();
    const $button = $(event.target);
    $.ajax({
      method: 'post',
      dataType: 'json',
      timeout: 2000,
      url: $button.data('action'),
      headers: {
        'X-Csrf-Token': $('meta[name=csrf-token]').attr('content'),
      },
      data: {
        jobid: $button.data('jobid'),
      }
    }).then(function(data) {
      if (data.error) {
        window.alert(data.error);
      } else {
        $('.js-job-favoritBtn[data-jobid='+$button.data('jobid')+']').addClass('is-added');
        const cnt = parseInt($('.c-header-favorit__number').text());
        $('.c-header-favorit__number').text(cnt + 1);
      }
    }).catch(function(err) {
      window.alert('お気に入りに追加出来ませんでした。時間を置いて再度お試しください。');
    });
  });
});