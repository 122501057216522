export function searchCondSalary () {
  if($('.js-searchCond-salary-inputs')[0]) {

    var $parent = $('.js-searchCond-salary-inputs'),
        $radioVal = null,
        $selectedText = '';
        /* メモ : セレクトボックスの初期値
            最優先 : class属性に 'is-selected' を持つもの
            次点　 : class属性に 'is-default' を持つもの */

    // ラジオボタン / セレクトボックスの値で状態変更する関数
    function checkRadioSalary ($event) {
      $radioVal = $('.js-searchCond-salary-radio:checked').attr('data-value');
      switch ($radioVal) {
        case '指定しない':
          // セレクトボックスを不活性化
          $parent.find('.c-select').addClass('is-disabled');
          $('.salary-select').prop('disabled', true);
          $selectedText = '';
          break;
        case '月収':
          // セレクトボックスを活性化
          $parent.find('.c-select').removeClass('is-disabled');
          $('.salary-select').prop('disabled', false);
          // 月収のoption要素を活性化
          $('.js-select-perMonth').unwrap('span');
          // 初期選択をセット
          if (!$('.js-select-perMonth.is-selected')[0]) {
            $('.js-select-perMonth.is-default').prop('selected', true);
          }
          // 選択フラグが存在した場合
          if ( $('.js-select-perMonth.is-selected').val() != $('.salary-select').val() ) {
            // 操作されたのがセセレクトボックスかを判定
            if ($event) {
              if ( !$event.hasClass('salary-select') ) {
                $('.js-select-perMonth.is-selected').prop('selected', true);
              } else {
                // 選択済みフラグを消去
                $('.js-select-perMonth').removeClass('is-selected')
              }
            } else {
              $('.js-select-perMonth.is-selected').prop('selected', true);
            }
          }
          // 時給のoption要素を不活性化
          if (!$('.js-select-perHour').parent('span')[0]) {
            $('.js-select-perHour').wrap('<span>');
          }
          // スマホ表示用テキストを抽出
          $selectedText = $('.salary-select option[value="' + $('.salary-select').val() + '"]').addClass('is-selected').text();
          break;
        case '時給':
          // セレクトボックスを活性化
          $parent.find('.c-select').removeClass('is-disabled');
          $('.salary-select').prop('disabled', false);
          // 時給のoption要素を活性化
          $('.js-select-perHour').unwrap('span');
          // 初期選択をセット
          if (!$('.js-select-perHour.is-selected')[0]) {
            $('.js-select-perHour.is-default').prop('selected', true);
          }
          // 選択フラグが存在した場合
          if ( $('.js-select-perHour.is-selected').val() != $('.salary-select').val() ) {
            // 操作されたのがセセレクトボックスかを判定
            if ($event) {
              if ( !$event.hasClass('salary-select') ) {
                $('.js-select-perHour.is-selected').prop('selected', true);
              } else {
                // 選択済みフラグを消去
                $('.js-select-perHour').removeClass('is-selected')
              }
            } else {
              $('.js-select-perHour.is-selected').prop('selected', true);
            }
          }
          // 月収のoption要素を不活性化
          if (!$('.js-select-perMonth').parent('span')[0]) {
            $('.js-select-perMonth').wrap('<span>');
          }
          // スマホ表示用テキストを抽出
          $selectedText = $('.salary-select option[value="' + $('.salary-select').val() + '"]').addClass('is-selected').text();
          break;
        default:
          // デフォルトで「指定しない」を選択
          $('.js-searchCond-salary-radio[data-value="指定しない"]').prop('checked', true);
          // セレクトボックスを不活性化
          $parent.find('.c-select').addClass('is-disabled');
          $('.salary-select').prop('disabled', true);
          if (!$('.js-select-perHour').parent('span')[0]) {
            $('.js-select-perHour').wrap('<span>');
          }
          $selectedText = '';
      }
    }

    // SP表示用のテキストを生成、セットする関数
    function textChange () {
			// SP表示のテキスト反映
			var $text;
      if (!$radioVal) {
        $radioVal = '指定しない';
      }
			if ($selectedText === '') {
				$text = $radioVal
			} else {
				$text = $radioVal + '/' + $selectedText;
      }
      $('.c-searchCond-condTable__text.is-salary').text($text);
      $selectedText = '';
    }

    // 初期セット
    checkRadioSalary();
    textChange();

    // ラジオボタン・セレクトボックスの操作
    $('.js-searchCond-salary-radio, .salary-select').on('change', function(){
      checkRadioSalary($(this));
      textChange();
    });
  }
}