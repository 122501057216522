$(function (){
	function slider_jobList_pcNoSlider () {
		$('.js-slider--jobListPcNoSlider').not('.slick-initialized').slick({
			dots: false,
			appendDots: $('.js-slider--jobListPcNoSlider').closest('.c-recommendedWork').find('.c-slider__dots'),
			arrows: true,
			appendArrows: $('.js-slider--jobListPcNoSlider').closest('.c-recommendedWork').find('.c-slider__arrows'),
			prevArrow: '<li class="c-slider__arrowLeft"><img src="/assets/images/btn-slider-prev.svg" alt="一つ前"></li>',
			nextArrow: '<li class="c-slider__arrowRight"><img src="/assets/images/btn-slider-next.svg" alt="一つ後"></li>',
			infinite: true,
			//autoplay: true,
			speed: 400,
      slidesToShow: 1,
			centerMode: true,
			centerPadding: '40px',
			variableWidth: true,
      mobileFirst: true,
			responsive: [
				{
					breakpoint: 767,
					settings: 'unslick',
				},
      ]
		});　// 223px + 10px*2 + 10px*2 = 263px / 526 / 789 / 
	}
	slider_jobList_pcNoSlider();

  // 画面幅の変更に応じてスライダーを調整
  var $winWidth = window.innerWidth;
	$(window).on('scroll', function(){
		$winWidthTemp = window.innerWidth;
		if ($winWidth != $winWidthTemp) {
			slider_jobList_pcNoSlider();
			$winWidth = $winWidthTemp;
		}
	});
});