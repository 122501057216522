export function modal_checkboxAll_monitoring ($key) {
  var $parent = $key.closest('.c-modal-select-lv1__item'),
      $target = $parent.find('.c-modal-select-lv1__heading .c-checkbox__input'),
      $bros   = $parent.find('.c-modal-select-lv2__item .c-checkbox__input'),
      $checedFlag = [];
  // 第2階層のチェックボックスが存在するときのみ実行
  if ($bros[0]) {
    // 兄弟にあたるinputのチェック状況を配列に格納
    $bros.each(function() {
      if ( $(this).prop('checked') ) {
        $checedFlag.push('checked');
      } else {
        $checedFlag.push('unchecked');
      }
    });
    // チェックされていないものが一つでもあれば、一括チェックのチェックを外す
    if ($checedFlag.indexOf('unchecked') > -1) {
      $target.prop('checked', false);
    } else {
      $target.prop('checked', true);
    }
  }
}