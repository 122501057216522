$(function(){

  // 初期セット
  function tab_default_set (){
    if (!$('.c-modal-tabNav__anchor[data-target]').length) {
      return;
    }
    // 一旦タブコンテンツを非表示
		$('.c-modal-tabTarget').hide();
    // 初期状態で「is-active」なタブがあるかチェック
    if ($('.c-modal-tabNav__anchor.is-active')[0]) {
      var $target = $('.c-modal-tabNav__anchor.is-active').eq(0).attr('data-target');
      $('.c-modal-tabTarget.' + $target).show();
    } else {
      var $activateTab = $('.c-modal-tabNav__item:nth-of-type(1)').find('.c-modal-tabNav__anchor');
      $activateTab.addClass('is-active');
      $('.c-modal-tabTarget.' + $activateTab.attr('data-target')).fadeIn(400);
    }
  }
  tab_default_set();

	// モーダル > エリア選択 > タブ切り替え
	$('.c-modal-tabNav__anchor[data-target]').on('click', function(e){
    e.preventDefault();
    var $target = $(this).attr('data-target');
    $('.c-modal-tabNav__anchor').removeClass('is-active');
    $(this).addClass('is-active');
    $('.c-modal-tabTarget').hide();
    $('.c-modal-tabTarget.' + $target).fadeIn(400);
    $(this).trigger('tab.change');
	});

});