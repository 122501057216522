// 年月日のセレクトボックスで、月によって日の項目を制御
$(function(){
  // 「日」までセレクトボックスがある場合に実行（= 「日」がなければ不要）
	if ($('.c-apply-input__row.is-date .is-day')[0]) {

    // 日のセレクトボックスを不活性化
		//$('.c-apply-input__row.is-date .is-day .c-select').addClass('is-disabled');

    // 年・月・日のセレクトボックスを操作したとき
		$('.c-apply-input__row.is-date select').on('change', function(){
      var $parent = $(this).parents('.c-apply-input__row.is-date'),
          $target = $parent.find('.c-apply-input__col.is-day select'),
					$year =  $parent.find('.c-apply-input__col.is-year select').val(),
					$month = $parent.find('.c-apply-input__col.is-month select').val();
			// 「日」の選択肢の不活性化を一旦解除
			//$target.find('option').removeClass('u-display--none');
			switch ($month) {
				// 2月
				case "2" :
					if ($year != 0 && Number($year) % 4 === 0){ // うるう年のとき
            $target.find('option').removeClass('u-display--none').unwrap('span');
						$target.find('option:nth-last-child(-n+2)').addClass('u-display--none').wrap('<span>');
					} else {
            $target.find('option').removeClass('u-display--none').unwrap('span');
						$target.find('option:nth-last-child(-n+3)').addClass('u-display--none').wrap('<span>');
					}
					break;
				// 4月、6月、9月、11月
				case "4" :
				case "6" :
				case "9" :
				case "11" :
          $target.find('option').removeClass('u-display--none').unwrap('span');
					$target.find('option:nth-last-child(-n+1)').addClass('u-display--none').wrap('<span>');
          break;
        // それ以外 = 1月、３月、５月、７月、８月、１０月、１２月
        default :
          $target.find('option').removeClass('u-display--none').unwrap('span');
			}

      // 日のセレクトボックスの不活性化を解除
			//$('.c-apply-input__row.is-date .is-day .c-select').removeClass('is-disabled');

      // 選択できない「日」が既に選択状態のときは、値をクリア
			if ( $target.find('option:selected').hasClass('u-display--none') ) {
        $target.find('option:selected').prop('selected', false)
        var timerID = setTimeout(function(){
          $target.focus();
          clearTimeout(timerID);
        }, 100);
			};
		});
	}
});