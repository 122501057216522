// 登録会予約 > カレンダーの「前の期間・次の期間」の切り替え
$(function(){
  $(document).on('click', '.js-apply-cal-btn', function(){
    var $cal_container = $(this).parents('.c-apply-cal-controller').nextAll('.c-apply-cal__container'),
        $wrapper = $cal_container.find('.c-apply-cal-wrapper'),
        $cal_all = $cal_container.find('.c-apply-cal-table'),
        $cal_1st = $cal_container.find('.c-apply-cal-table.is-1stWeek'),
        $cal_2nd = $cal_container.find('.c-apply-cal-table.is-2ndWeek'),
        $cal_3rd = $cal_container.find('.c-apply-cal-table.is-3rdWeek'),
        $num = Number($wrapper.attr('data-displayCal')),
        $maxNum = $cal_all.length;

    //カレンダーのスクロールをリセット
    $wrapper.scrollLeft(0);

    // カレンダー切り替え処理
    if ( $(this).hasClass('is-next') ) {
      // 次の期間を表示
      $cal_all.eq($num).removeClass('is-hidden');
      // 表示カウンターを更新
      $num += 1;
      $wrapper.attr('data-displayCal', $num);
      // 一旦全ボタンを活性化
      $('.js-apply-cal-btn').removeClass('is-disabled');
      // これ以上スライドできないときはボタンを不活性化
      if ($num === $maxNum) {
        $(this).addClass('is-disabled');
      }
      //すべてのカレンダーを左方向にスライド
      $cal_all.animate(
        {
          left: '-=100%',
        },
        400,
        function(){
          // アニメーション後、現在の期間を非表示
          $cal_all.eq($num - 2).addClass('is-hidden');
        }
      );
    } else if ( $(this).hasClass('is-prev')) {
      // 前の期間を表示
      $cal_all.eq($num - 2).removeClass('is-hidden');
      // 表示カウンターを更新
      $num -= 1;
      $wrapper.attr('data-displayCal', $num);
      // 一旦全ボタンを活性化
      $('.js-apply-cal-btn').removeClass('is-disabled');
      // これ以上スライドできないときはボタンを不活性化
      if ($num === 1) {
        $(this).addClass('is-disabled');
      }
      //すべてのカレンダーを右方向にスライド
      $cal_all.animate(
        {
          left: '+=100%',
        },
        400,
        function(){
          // アニメーション後、現在の期間を非表示
          $cal_all.eq($num).addClass('is-hidden');
        }
      );
    }
  });
});
