import { searchCondSalary } from './search-cond-salary';

export function searchCondSalaryModal_ready () {
  // ベースHTMLからモーダルにフォームパーツを移動する
  var $input = $('.js-searchCond-salary-inputs');
  $('.c-modal-selectSalary').append($input);

  // モーダルを開いたときの選択状態を記録
  var defaultFalg__radio = 'js-modal-selectSalary-default--radio',
      defaultFalg__select = 'js-modal-selectSalary-default--select',
      defaultText = $('.c-searchCond-condTable__text.is-salary').text();
  $('.js-searchCond-salary-radio:checked').addClass(defaultFalg__radio);
  $('.salary-select option[value="' + $('.salary-select').val() + '"]').addClass(defaultFalg__select);

  // 「選択する」ボタン クリック時の処理
  $('#modal-selectSalary .c-modal-button__btn').off('click.select.salary').on('click.select.salary', function(){
    // モーダルオープン時の選択フラグを削除
    $('.js-searchCond-salary-radio').removeClass(defaultFalg__radio);
    $('.salary-select option').removeClass(defaultFalg__select);
    // モーダルを閉じる
    $('.js-modal-searchCond--salary').modaal('close');
  });

  // 閉じるボタンをクリックしたときの処理
  $('#modal-selectSalary .c-modal-button__closeBtn').off('click.close.salary').on('click.close.salary', function(){
    // ラジオボタンをモーダルを開いた状態に戻す
    $('.' + defaultFalg__radio).prop('checked', true).removeClass(defaultFalg__radio);
    // セレクトボックスをモーダルを開いた状態に戻す
    $('.salary-select option').removeClass('is-selected').unwrap('span');
    var timerID = setTimeout (function (){
      $('.' + defaultFalg__select).prop('selected', true).addClass('is-selected').removeClass(defaultFalg__select);
      clearTimeout(timerID);
      searchCondSalary();
    }, 60);
    $('.c-searchCond-condTable__text.is-salary').text(defaultText)
    // モーダルを閉じる
    $('.js-modal-searchCond--salary').modaal('close');
  });
}