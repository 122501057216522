// エントリーシート作成 > ブロック追加
$(function(){
  // 非表示の入力要素をdisabledに
  $('.c-entrysheet-repaetBlk.is-hidden').find('input, select, textarea').prop('disabled', true);

	$('.js-addBlk').on('click', function(e){
    var $indicator = null,
        $nextFocus = null;
    var $nextWarpper;
    switch ($(this).attr('data-type')) {
       case 'schoolBlock' :
        $indicator = 'school_add';
        $nextWarpper = '.c-apply-input__row';
        $nextFocus = 'input';
        break;
      case 'jobBlock' :
        $indicator = 'job_add';
        $nextWarpper = '.c-entrysheet-businessEx-term';
        $nextFocus = 'select';
        break;
      case 'certificationBlock' :
        $indicator = 'shikaku_add'
        $nextWarpper = '.c-apply-input__row';
        $nextFocus = 'select';
        break;
    }
		e.preventDefault();
		// 隠しボックスのうち、最初のボックスを表示
    var $block = $(this).parents('.c-entrysheet-repaetBlk__add').prevAll('.c-entrysheet-repaetBlk.is-hidden').eq(-1);
    $block.find('input, select, textarea').prop('disabled', false);
    $block.removeClass('is-hidden').fadeIn(400);
    if ($indicator === 'school_add') {
      const checkAddedEl = document.querySelector('[name="forFtra_isAddedEducationalBackgrounds"]')
      if (checkAddedEl) {
        checkAddedEl.setAttribute('value', '1')
      }
    }
    $block.find('.js-ftra-requiredFlag').prop('checked', true).nextAll($nextWarpper).find($nextFocus).eq(0).focus();
		// 上限に達している場合、追加ボタンを不活性化
		if (!$(this).parents('.c-entrysheet-repaetBlk__add').prev('.is-hidden')[0]) {
			$(this).addClass('is-disabled');
		}
	});

	// エントリーシート作成 > ブロック削除
	$('.js-es-removeBlk').on('click', function(e){
    var $indicator = null,
        $nextFocus = null;
    var $nextWarpper;
    switch ($(this).attr('data-type')) {
      case 'schoolBlock' :
       $indicator = 'school_add';
       $nextWarpper = '.c-apply-input__row';
       $nextFocus = 'input';
       break;
     case 'jobBlock' :
       $indicator = 'job_add';
       $nextWarpper = '.c-entrysheet-businessEx-term';
       $nextFocus = 'select';
       break;
     case 'certificationBlock' :
       $indicator = 'shikaku_add'
       $nextWarpper = '.c-apply-input__row';
       $nextFocus = 'select';
       break;
    }
		e.preventDefault();
		var $target = $(this).parents('.c-entrysheet-repaetBlk'),
				$targetBtn = $target.nextAll('.c-entrysheet-repaetBlk__add')
		// 該当ボックスを隠す
    $target.find('input, select, textarea').prop('disabled', true);
    $target.fadeOut(400).addClass('is-hidden').insertBefore($targetBtn).find('.js-ftra-requiredFlag').prop('checked', false);
		// 該当ボックスのフォームをクリア
		$target.find('textarea, input[type=text], select').val('').end().find(':checked').prop('checked', false);
    if ($indicator === 'school_add') {
      const checkAddedEl = document.querySelector('[name="forFtra_isAddedEducationalBackgrounds"]')
      if (checkAddedEl) {
        const count = $target.prevAll('.c-entrysheet-repaetBlk:not(.is-hidden)').length
        const newValue = count === 0 ? '0' : '1'
        if (newValue !== checkAddedEl.getAttribute('value')) {
          checkAddedEl.setAttribute('value', newValue)
          checkAddedEl.dispatchEvent(new Event('focus'))
        }
      }
    }
    $target.prevAll('.c-entrysheet-repaetBlk').find($nextWarpper).eq(0).find($nextFocus).eq(0).focus()
		// 追加ボタンの活性化
		$targetBtn.find('.js-addBlk').removeClass('is-disabled');
	});

});
