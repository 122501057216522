$(function (){
  if ($('.js-aiChat')[0]) {

    var webchatUrl = $('.js-aiChat--body').attr('data-src');
    var isOpenDialog = function() {
      return sessionStorage.getItem('is_open_chat') === "1";
    }
    // チャットウィンドウを開く処理
    function open_chat () {
      if (window.innerWidth < 768) {
        _no_scroll()
      }
      $('.js-aiChat--open').hide();
      sessionStorage.setItem('is_open_chat', 1);
      if ($('.js-aiChat--body').attr('src') === "") {
        $('.js-aiChat--body').attr('src', webchatUrl);
      }
      $('.js-aiChat--window').addClass('is-opened').show();
    }
    // チャットウィンドウを閉じる処理
    function close_chat () {
      if (window.innerWidth < 768) {
        _return_scroll();
      }
      sessionStorage.setItem('is_open_chat', 0);
      $('.js-aiChat--open').show();
      $('.js-aiChat--window').removeClass('is-opened').hide();
    }

    // 初期実行 → セッションストレージにフラグがあれば、チャットウィンドウを開く
    if (isOpenDialog()) {
      open_chat();
    }

    // チャット窓を開く
    $('.js-aiChat--open').on('click', function(e){
      e.preventDefault();
      open_chat();
    })

    // チャット窓を閉じる
    $('.js-aiChat--close').on('click', function(e){
      e.preventDefault();
      close_chat();
    })

    // 画面リサイズの処理
    var resizeInterval = true;
    function resizeWindow () {
      tempWinWidth = window.innerWidth;
      if (winWidth != tempWinWidth) {
        // SP表示 → PC表示に変形
        if (winWidth < 768 && tempWinWidth >= 768) {
          if ($('.js-aiChat--window').hasClass('is-opened')) {
            _return_scroll();
          }
        }
        // PC表示 → SP表示に変形
        else if (winWidth >= 768 && tempWinWidth < 768) {
          if ($('.js-aiChat--window').hasClass('is-opened')) {
            _no_scroll();
          }
        }
        winWidth = tempWinWidth;
      }
    }
    $(window).on('resize', function(){
      if (resizeInterval) {
        resizeInterval = false;
        resizeWindow ();
        var timer = setTimeout (function(){
          resizeInterval = true;
        }, 50)
      }
    });


    var ScrollPositionX,
        ScrollPositionY,
        winWidth = window.innerWidth,
        tempWinWidth;
    function _no_scroll() {
      ScrollPositionY = $(window).scrollTop();
      ScrollPositionX = $(window).scrollLeft();
      $('body').addClass('noscroll').css({'top': (-ScrollPositionY), 'left': (-ScrollPositionX)});
    }
    //スクロール復活関数
    function _return_scroll() {
      $('body').removeClass('noscroll');
      $(window).scrollTop(ScrollPositionY);
    }

  }
});