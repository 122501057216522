/** 現在選択中エリア */
window.getCurrentArea = function() {
  const currentArea = document.cookie.replace(/(?:(?:^|.*;\s*)area\s*\=\s*([^;]*).*$)|^.*$/, '$1') || 'all'
  const areas = [
    'all',
    'chugoku-shikoku',
    'hokkaido-tohoku',
    'kansai',
    'kanto',
    'koshinetsu-hokuriku',
    'kyushu-okinawa',
    'tokai'
  ]
  if (areas.indexOf(currentArea) !== -1) {
    return currentArea
  }
  return 'all'
}