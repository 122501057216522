$(function (){
	function jobDetail_image_slider () {
		$('#job-images-slider').not('.slick-initialized').slick({
			dots: false,
			arrows: true,
			appendArrows: $('.c-job-images .c-slider__arrows'),
			prevArrow: '<li class="c-slider__arrowLeft"><img src="/assets/images/btn-slider-prev.svg" alt="一つ前の画像"></li>',
			nextArrow: '<li class="c-slider__arrowRight"><img src="/assets/images/btn-slider-next.svg" alt="一つ後の画像"></li>',
			infinite: true,
			//autoplay: true,
			speed: 400,
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 767,
					settings: 'unslick',
				}
			]
		});
	}
	jobDetail_image_slider();
	var $winWidth = window.innerWidth;
	$(window).on('scroll', function(){
		$winWidthTemp = window.innerWidth;
		if ($winWidth != $winWidthTemp) {
			jobDetail_image_slider();
			$winWidth = $winWidthTemp;
		}
	});
});