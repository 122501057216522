$(function (){

  if ($('.js-news-selectYear')[0]) {

    // 初期セット
    $('.js-news-selectYear').find('.c-news-selectYear__item:not(.is-current)').hide();
    // クリックでメニューを開く
    $('.js-news-selectYear .is-current a, .js-news-selectYear .is-placeholder a').on('click', function(e){
      e.preventDefault();
      if (!$(this).hasClass('is-opend')) {
        $(this).addClass('is-opend')
        $('.js-news-selectYear .is-placeholder a').addClass('is-opend')
        $('.c-news-selectYear__item').slideDown();
      } else {
        $('.js-news-selectYear .is-current a').removeClass('is-opend')
        $('.c-news-selectYear__item:not(.is-current)').slideUp();
      }
    });

  }

});