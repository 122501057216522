// import 'core-js/stable';
// import 'regenerator-runtime/runtime';

// 共通パーツ ///////////////////////////////////////
	import './globals'
	// ヘッダー
	import './header/areaSelector';
	import './header/authInfo';
	// PC/SP表示変更
	import './header/spNavi';

	// おすすめのお仕事（スライダー）
	import './recommendedWork-slider';
	// 年月日のセレクトボックスで、月によって日の項目を制御
	import './utility/select-day-control';
	// 年月日のセレクトボックスがすべて選択されるとボタンを活性化
	import './utility/check-input-birthday';
	// チェックボックの一括制御
	import './utility/select-checkboxAll';
	// チェックボックの一括制御 > 状態監視
	import './utility/checkbox-checkAll-monitoring';
	// スライダー
	import './utility/jobList-slider';
	import './utility/jobList-slider--pc-noSlider';
	// アコーディオン
	import './utility/accordion';
	// スムーススクロール
	import './utility/smoothScroll';
	// スクロールヒント
	import './utility/scrollHint';
	// フォーム > セレクトボックス > プレースホルダー代わりに「選択してください」がある場合
	import './utility/form-select-placeholder_fontsize';
	// フォーム > 多重クリック防止
	import './utility/rejectRepeatedHits';
	// エフトラEOF の「エラー時背景色設定」を上書き
	//import './utility/ftra-override-background';
	// AIチャット
	import './utility/chat';


// モーダル ////////////////////////////////////////
	// モーダルの開閉
	import './modal/modal-open';
	// タブ切り替え
	import './modal/modal-tabNavigation';
	// エリア選択モーダル > チェックボックス一括制御
	import './modal/modal-select-checkboxAll';
	// モーダル > アコーディオンメニューの開閉
	//import './modal/modal-accordion'; → modal-oepn.js に移動


// トップページ //////////////////////////////////////
  // 検索条件切替タブ
  import './home/home-banner-slider';
  // さらに表示する
  import './home/home-more-expand';


// お知らせ ///////////////////////////////
  // 年選択 → リンク
  import './news/news-selectYear';


// 会員登録 ///////////////////////////////
  // パスワードを見える化
  import './member/menber-revealPassword';


// お気に入り追加 ///////////////////////////////
  import './jobCommon/addFavorite';

// 仕事検索 ////////////////////////////////////////
  // 件数
  //import { CountUp } from './jobSearch/vendor/countUp.min';
  import { search_resultCount } from './jobSearch/search-resultCount';
  search_resultCount();
	// 選択状態の復元
	import './jobSearch/restoreSeachCond';
	// 検索条件切替タブ
	import './jobSearch/search-cond-tab';
	// 下部固定検索エリア
	import './jobSearch/search-fixed-area';
	// 自宅の近くでさがす
	import { searchCond_area_nearHome } from './jobSearch/search-cond-nearHome';
	searchCond_area_nearHome();
	// 給与条件制御
	import { searchCondSalary } from './jobSearch/search-cond-salary';
	searchCondSalary();


// 仕事詳細 ////////////////////////////////////////
	// ボタンエリア > 複製→画面下部張り付き制御
	import './jobDetail/jobDetail-btns-clone';
	// ボタンエリア > SP表示 > 電話で応募ボタンクリックでウィンドウの開閉
	import './jobDetail/jobDetail-btns-telWindow';
	// お仕事 > ヘッダー > 大画像切り替え
	import './jobDetail/jobDetail-heaerThumbs-slider';
	// お仕事 > 強化原稿 画像 > 大画像切り替え
	import './jobDetail/jobDetail-image-largeScreen';
	// お仕事 > 強化原稿 画像 > サムネール (スライダー)
	import './jobDetail/jobDetail-image-slider';
	// 募集要項 > さらに表示する
	import './jobDetail/jobDetail-requirement-expand';
	// スタッフ紹介 > さらに表示する
	import './jobDetail/jobDetail-staff-expand';
	// 閲覧履歴 
	import './jobDetail/jobDetail-history';


// 応募フォーム ////////////////////////////////////////
	// 応募フォーム > 登録会予約 > タブ切り替え(SP)
	import './apply/applyType-tabNavigation';
	// 登録会予約 > 会場 / 日時選択 → 次の選択項目を表示してスクロール
	import './apply/apply-scrollNextSection';
	// 登録会予約 > 会場から選ぶ・WEB面接 > 前の期間・次の期間でカレンダーを切り替え
	import './apply/applyType-change-term';
	// 登録会予約 > 日付から選ぶ > 該当の日程に面接の空きがない場合の戻る処理
	import './apply/applyType-goBack-selectDate';
	// 登録会予約 > 日付から選ぶ > 会場 > 時刻を選択すると「予約する」ボタンが活性化
	import './apply/applyType-date-selectTime';
	// 応募フォーム > 入力の残り項目数ブロックをスティッキー処理
	import './apply/apply-stickyRemainingCounter';


// エントリーシート作成 ///////////////////////////////
	// エントリーシート作成 > 職歴・資格 のラジオボタンで入力ブロック表示を制御
	import './entrysheet/entrysheet-checkRadioCond';
	// エントリーシート作成 > ブロック追加 / 削除
	import './entrysheet/entrysheet-addBlock';
	// エントリーシート作成 > 希望条件 : Datepicker
	import { es_datepicker } from './entrysheet/entrysheet-datepicker';
	es_datepicker();
  // 適正テストのリンクをクリックしたら、不活性化
  import './entrysheet/aptitudeTest-link';


// ログイン ///////////////////////////////
	// ログインページ > メアド・パスワード入力でログインボタンを活性化
	import './login/login-check-input';

// マイページ ///////////////////////////////
	import './myPage/myPage-favorite-page-buttons';

