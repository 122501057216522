// 入力の残り項目数ブロックを画面上部に貼り付け
$(function() {
	if ($('.c-apply-remaining')[0] && $('.c-apply-inputWrapper')[0]) { // 残り項目数ブロックがあるときのみ処理

    // 変数設定
    var $winWidth = window.innerWidth,
				$tempWinWidth = null,
				$tempContentHeight = null,
				timerID = "", // 間引き処理用のタイマー設定
				$scrollTop = null,
				$boxHeight = null,
				$target = $('.c-apply-remaining'),
				$targetOffsetTop = null,
        $targetOffsetLeft = null,
        $stickyEndOffsetTop = null,
				$targetWidth = null,
				$content = $('.c-apply-inputWrapper'),
				//$contentOffsetLeft = null,
				//$contentWidth = $content.width(),
        $contentHeight = $content.height(),
        $UA = window.navigator.userAgent.toLowerCase();

    // 各種変数の初期設定・画面サイズ変更による再定義
		function initVar () {
			//残り項目数ブロックの高さを定義
			if (window.innerWidth < 768) {
				$boxHeight = 34;
			} else {
				$boxHeight = 70;
			}
      $targetOffsetTop = $content.offset().top; // 画面上部に張り付くスクロール量を定義
      $stickyEndOffsetTop = $content.offset().top + $content.height() - $boxHeight; // 画面上部に張り付きが終了するスクロール量を定義
      $targetWidth = $target.width();
      $targetOffsetLeft = $content.offset().left + $('.c-apply-form').width() - $targetWidth;
		}

    // 画面サイズ変更を監視する関数
		function check_windowSize () {
			$tempWinWidth = window.innerWidth;
      $tempContentHeight = $content.height();
			if ($winWidth != $tempWinWidth || $contentHeight != $tempContentHeight) {
				$winWidth = $tempWinWidth;
				$contentHeight = $tempContentHeight;
				initVar();
			}
		}

    // 張り付き処理を実行する関数
		function check_scrollTop () {
			check_windowSize ();
      $scrollTop = $(window).scrollTop();
			// 上に張り付く
			if ( $targetOffsetTop < $scrollTop && $stickyEndOffsetTop > $scrollTop ) {
				$target.addClass('is-fixed').removeClass('is-fixedBottom').css('top', 0);
        // 張り付くボックスの横位置を調整（旧Edge・IE向け）
        if( $UA.indexOf('trident/7') !== -1 || $UA.indexOf('edge') !== -1 ) {
          if ($winWidth >= 768) {
            $target.css('left', $targetOffsetLeft);
          } else {
            $target.css('left', '0');
          }
        }
			// 張り付き終了
			} else if ($stickyEndOffsetTop < $scrollTop) {
				$target.addClass('is-fixedBottom').css('top', $content.height() - $boxHeight);
        // 張り付くボックスの横位置を調整（旧Edge・IE向け）
        if( $UA.indexOf('trident/7') !== -1 || $UA.indexOf('edge') !== -1 ) {
          if ($winWidth >= 768) {
            $target.css('left', $('.c-apply-form').width() - $targetWidth);
          } else {
            $target.css('left', '0');
          }
        }
			// 初期状態に戻す
			} else {
				$target.removeClass('is-fixed is-fixedBottom');
			}
		}

    // 初期実行
    initVar ();
    check_scrollTop ();

    // 間引きながら、張り付きの状態を制御
		$(window).on('scroll resize', function(){
			if (timerID === "") {
				timerID = setTimeout(function() {
					check_scrollTop();
					timerID = "";
				}, 60);
			}
		});

  }
});