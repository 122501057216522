$(function(){
  if ($('.c-apply-applyType')[0]) {

    // 応募フォーム > 登録会予約 > タブ 初期セッティング
    // 最初からアクティブ（.is-selected）があるとき
    if ($('.c-apply-applyType__anchor.is-selected')[0]) {
      // アクティブなタブを展開
      $('.c-apply-applyType__anchor.is-selected').eq(0).nextAll('.c-apply-applyType__content').show();
    } else {
      if (window.innerWidth >= 768) {
        $('.c-apply-applyType__item.is-place')
          .find('.c-apply-applyType__anchor').addClass('is-selected').nextAll('.c-apply-applyType__content').show();
      }
    }


    // 応募フォーム > 登録会予約 > タブ切り替え(SP)
    $('.c-apply-applyType__anchor:not(".is-link")').on('click', function(){

      // 表示をリセットする関数
      function hideContent (){
        $('.c-apply-applyType__anchor').removeClass('is-selected');
        $('.c-apply-applyType__content').hide();
      }

      // タブ切り替え処理
      if (!$(this).hasClass('is-selected')) {
        hideContent ();
        $(this).addClass('is-selected').nextAll('.c-apply-applyType__content').fadeIn(400);
        // 該当エリアの先頭が見切れている場合の処理
        var $targetOffsetTop = $(this).offset().top;
        if ( $targetOffsetTop < $(window).scrollTop()) {
          $(window).scrollTop($targetOffsetTop - 10);
        }
        new ScrollHint('.js-scrollable');
      } else {
        if (window.innerWidth < 768) {
          hideContent ();
        }
      }
    })

  }

});