import {
  addMonths, eachDayOfInterval, eachWeekOfInterval, endOfMonth, endOfWeek, isBefore, isSameDay, isSameMonth, isToday, startOfMonth, format
} from 'date-fns';
import { ja } from 'date-fns/locale';

// 登録会予約 > 会場 / 日時選択 → 次の選択項目を表示してスクロール
$(function(){
  async function loadPlaceItems($parent, pref) {
    removePlaceItems($parent);
    const isOnlySelectVenue = $parent.attr('data-is-only-select-venue') !== undefined;
    await $.ajax({
      type: 'GET',
      timeout: 5000,
      dataType: 'json',
      url: `/apply/api/venues?pref_code=${pref}`,
    }).then(function(response) {
      if (response.data.length === 0) {
        // Note: サーバーテンプレート側でも同じ文言あるので変更時は合わせて
        const $nohitBlock =  $(`
          <div class="c-apply-place__hit0">
            <div class="c-apply-place-hit0__box">
              <p class="c-apply-place-hit0__txt">
                ご希望都道府県内の予約可能な会場は現在ありません。<br>
                都道府県を変更していただくか、後日改めてご予約お願いします。
              </p>
            </div>
          </div>`);
        $parent.append($nohitBlock);
        return;
      }
      response.data.forEach(venue => {
        $parent.append(createPlaceItem(venue.id, venue.name, venue.displayAddress, isOnlySelectVenue));
      });
    });
  }
  function createPlaceItem(id, name, address, isOnlySelectVenue) {
    const $item = $('<div>', { class: 'c-apply-place__item' });
    // タイトル
    const $itemTitle = $('<p>', { class: 'c-apply-place-item__title', text: name });
    $item.append($itemTitle);
    // 住所 + Mapボタン
    const $itemAddress = $('<p>', { class: 'c-apply-place-item__address', text: address });
    $itemAddress.append(
      $('<button>', {
        type: 'button',
        class: 'c-apply-place-item__map js-modal-apply--map',
        text: 'Map',
        'data-venue': id
      })
    );
    $item.append($itemAddress);
    // 選択ボタン
    if (!isOnlySelectVenue) {
      const $itemButton = $('<button>', {
        type: 'button',
        class: 'c-button__btn c-apply-place-item__btn js-applyType-nextStep',
        text: '選択する',
        'data-venue': id
      });
      $item.append($itemButton);
    } else { // 会場検索 (UVEN010)
      const $itemButton = $('<a>', {
        href: '/apply/input?venue='+id,
        class: 'c-button__btn--pt2 c-apply-place-item__btn',
        text: '選択する',
      });
      $item.append($itemButton);
    }

    return $item
  }
  function removePlaceItems($parent) {
    $parent.find('.c-apply-place__hit0').remove();
    $parent.find('.c-apply-place__item').remove();
  }

  async function loadPlaceCalendar($parent, venue) {
    removePlaceCalendar($parent);
    await $.ajax({
      type: 'GET',
      timeout: 5000,
      dataType: 'json',
      url: `/apply/api/vacant_dates?venue=${venue.id}`,
    }).then(function(response){
      $parent.find('.c-apply-cal-controller__name').text(venue.name);
      const total = response.data.length;
      [...Array(Math.ceil(total / 7))].forEach((v, index) => {
        const vacantDates = response.data.slice(index * 7, Math.min((index + 1) * 7, total))
        vacantDates.forEach((vacantDate, vai) => {
          const shownMonth = vai === 0 || new Date(vacantDate.date).getDate() === 1;
          $parent.find('.c-apply-cal-table').eq(index).append(createPlaceCalendar($parent, venue.id, vacantDate, shownMonth));
        });
      });
    });
  }
  function createPlaceCalendar($parent, venue, vacantDate, shownMonth) {
    const date = new Date(vacantDate.date);
    const $item = $('<div>', { class: 'c-apply-cal-column' });
    // タイトル
    const $itemHeading = $('<p>', { class: 'c-apply-cal-table__heading' });
    $itemHeading.append($('<span>', {
      class: 'c-apply-cal-table__month',
      text: shownMonth ? format(date, 'yyyy年MMM', { locale: ja }) : ''
    }));
    $itemHeading.append($('<span>', {
      class: 'c-apply-cal-table__day',
      text: format(date, 'dd日', { locale: ja }) + ' (' + ja.localize.day(date.getDay(), { width: 'short' }) + ')'
    }));
    $item.append($itemHeading);

    const times = [
      '08:00',
      '08:30',
      '09:00',
      '09:30',
      '10:00',
      '10:30',
      '11:00',
      '11:30',
      '12:00',
      '12:30',
      '13:00',
      '13:30',
      '14:00',
      '14:30',
      '15:00',
      '15:30',
      '16:00',
      '16:30',
      '17:00',
      '17:30',
      '18:00',
      '18:30',
      '19:00',
      '19:30',
      '20:00',
      '20:30',
      '21:00',
      '21:30',
    ];
    times.forEach(time => {
      const $itemCell = $('<div>', { class: 'c-apply-cal-table__cell' });
      const vacantTime = vacantDate.times.find(_time => _time.time == time);
      if (vacantTime && vacantTime.available) {
        const $itemButton = $('<button>', { type: 'submit', class: 'c-button__btn--pt2 js-apply-venueTab-reserveButton', text: '予約する', 'data-date': format(date, 'yyyyMMdd'), 'data-time': time });
        $itemCell.append($itemButton);
      } else {
        $itemCell.text('×');
      }
      $item.append($itemCell);
    })

    return $item
  }
  function removePlaceCalendar($parent) {
    $parent.find('.c-apply-cal-column').remove();
  }

  async function loadDateCalendar($parent, pref) {
    removeDateCalendar($parent);
    await $.ajax({
      type: 'GET',
      timeout: 5000,
      dataType: 'json',
      url: `/apply/api/vacant_calendar?pref_code=${pref}`,
    }).then(function(response) {
      Object.values(response.data).forEach((data, index) => {
        const $container = createDateCalendar(data, index);
        $parent.children('.c-apply-cal__containerOuter').append($container);
      });
    });
  }
  function createDateCalendar(data, increment) {
    const today = new Date();
    const _startOfMonth = startOfMonth(addMonths(today, increment));
    const $container = $('<div>', { class: 'c-apply-cal__container' });
    $container.append($('<div>', {
      class: 'c-apply-cal-table__month',
      text: format(_startOfMonth, 'yyyy年MMM', { locale: ja })
    }));

    const $table = $('<table>', { class: 'c-apply-cal-table__table' });
    const $tbody = $('<tbody>');
    const $header = $('<tr>');
    ['日', '月', '火', '水', '木', '金', '土'].forEach(dayOfWeek => {
      $header.append($('<th>', { class: 'c-apply-cal-table__week', text: dayOfWeek }));
    });
    $tbody.append($header);

    const sundays = eachWeekOfInterval({ start: _startOfMonth, end: endOfMonth(_startOfMonth) });
    sundays.forEach(sunday => {
      const $week = $('<tr>');
      const days = eachDayOfInterval({start: sunday, end: endOfWeek(sunday)});
      days.forEach(day => {
        const vacantDate = data.find(datum => datum && datum.date && isSameDay(new Date(datum.date), new Date(day)));
        const enabled = vacantDate && vacantDate.available || false;
        $week.append($('<td>', {
          class: 'c-apply-cal-table__cell-pt2',
          html: (isBefore(today, day) || isToday(day)) && isSameMonth(_startOfMonth, day) ? $('<button>', {
            class: 'c-button__btn c-apply-cal-table__btn ' + (enabled ? 'js-applyType-nextStep' : 'is-disabled'),
            disabled: !enabled,
            text: day.getDate(),
            'data-date': format(day, 'yyyyMMdd')
          }) : '&nbsp;'
        }));
      });
      $tbody.append($week);
    });

    $table.append($tbody);
    $container.append($table);

    return $container
  }
  function removeDateCalendar($parent) {
    $parent.find('.c-apply-cal__container').remove();
  }

  async function loadDatePlace($parent, pref, date) {
    removeDatePlace($parent);
    await $.ajax({
      type: 'GET',
      timeout: 5000,
      dataType: 'json',
      url: `/apply/api/reservable_venues?pref_code=${pref}&date=${date}`,
    }).then(function(response) {
      const formattedDate = `${date.substr(0, 4)}年${date.substr(4, 2)}月${date.substr(6, 2)}日`
      $parent.find('.c-apply-place-date').text(formattedDate);
      if (response.data.length > 0) {
        response.data.forEach(venue => {
          $parent.append(createDatePlace($parent, venue, date, venue.availableTimes));
        });
      } else {
        const $item = $('<div>', { class: 'c-apply-place__hit0' });
        $item.append($('<p>', { class: 'c-apply-place-hit0__date', text: formattedDate }));
        const $itemBox = $('<div>', { class: 'c-apply-place-hit0__box' });
        const $itemTxt = $('<p>', { class: 'c-apply-place-hit0__txt' });
        $itemTxt.text('この日付での空席はありません。日時を変更してご予約ください。');
        $itemTxt.append($('<br>'));
        $itemTxt.append($('<br>'));
        $itemTxt.append($('<a>', {
          href: '#',
          class: 'c-apply-place-hit0__anchor js-apply-goBack-selectDate',
          text: '日時を再選択する'
        }));
        $itemBox.append($itemTxt);
        $item.append($itemBox);
        $parent.append($item);
      }
    });
  }
  function createDatePlace($parent, venue, date, times) {
    const $item = $('<div>', { class: 'c-apply-place__item' });
    // タイトル
    const $itemTitle = $('<p>', { class: 'c-apply-place-item__title', text: venue.name });
    $item.append($itemTitle);
    // 住所 + Mapボタン
    const address = venue.displayAddress;
    const $itemAddress = $('<p>', { class: 'c-apply-place-item__address', text: address });
    $itemAddress.append(
      $('<button>', {
        type: 'button',
        class: 'c-apply-place-item__map js-modal-apply--map',
        text: 'Map',
        'data-venue': venue.id,
      })
    );
    $item.append($itemAddress);
    // 選択ボタン
    const $itemTime = $('<div>', { class: 'c-apply-place-item__selectTime' });
    $itemTime.append($('<p>', { class: 'c-apply-place-item__selectTimeTitle', text: '面接開始時間' }));
    const $itemTimeSpan =  $('<span>', { class: 'c-select' });
    const $itemTimeSelect =  $('<select>', { class: 'js-apply-date-selectTime' });
    $itemTimeSelect.append($('<option>', { value: '', class: 'c-select__placeholder', text: '面接開始時間' }));
    times.forEach(time => {
      $itemTimeSelect.append($('<option>', { value: time, text: `${time}〜` }));
    });
    $itemTimeSpan.append($itemTimeSelect);
    $itemTime.append($itemTimeSpan);
    const $itemButton = $('<button>', { type: 'submit', class: 'c-button__btn--pt2 c-apply-place-item__btn is-disabled js-apply-dateTab-reserveButton', text: '予約する' });
    $itemTime.append($itemButton);
    $item.append($itemTime);

    return $item;
  }
  function removeDatePlace($parent) {
    $parent.find('.c-apply-place__item').remove();
    $parent.find('.c-apply-place__hit0').remove();
  }

  async function preparePlaceForm(_this) {
    let $parent;
    switch (true) {
      case $(_this).parents('.c-apply-selectArea')[0] !== undefined:
        const pref = $(_this).parents('.c-apply-selectArea').find('select').val();

        $parent = $(_this).parents('.c-apply-selectArea').next('.c-apply-place');
        await loadPlaceItems($parent, pref);
        break;
      case $(_this).parents('.c-apply-place')[0] !== undefined:
        const venue = {
          id: $(_this).attr('data-venue'),
          name: $(_this).closest('.c-apply-place__item').find('.c-apply-place-item__title').text()
        };
        // 選択IDをinputタグにセット
        $(_this).parents('.c-apply-place').find('input[name=venue]').val(venue.id);

        $parent = $(_this).parents('.c-apply-place').next('.c-apply-cal');
        await loadPlaceCalendar($parent, venue);
        break;
      // case $(_this).parents('.c-apply-cal')[0] !== undefined:
      //   break;
    }

    $(_this).addClass('is-selected');
    $(this).parents('.c-apply-place__item').addClass('is-selected');
  }
  async function prepareDateForm(_this) {
    let $parent;
    let pref;
    switch (true) {
      case $(_this).parents('.c-apply-selectDate')[0] !== undefined:
        pref = $(_this).parents('.c-apply-selectDate').find('select').val();

        $parent = $(_this).parents('.c-apply-selectDate').next('.c-apply-cal');
        await loadDateCalendar($parent, pref);
        break;
      case $(_this).parents('.c-apply-cal')[0] !== undefined:
        pref = $(_this).parents('.c-apply-cal').prev('.c-apply-selectDate').find('select').val();
        const date = $(_this).attr('data-date');
        // 選択日付をinputタグにセット
        $(_this).parents('.c-apply-cal').find('input[name=date]').val(date);

        $parent = $(_this).parents('.c-apply-cal').next('.c-apply-place');
        await loadDatePlace($parent, pref, date);
        break;
    }
    $(_this).addClass('is-selected');
  }

  function goToNextArea ($target){
    $($target).parents('.js-applyType--target').nextAll('.js-applyType--target').hide();
		$($target).parents('.js-applyType--target').next('.js-applyType--target').fadeIn();
    if (!$($target).data('no-scroll')) {
  		// 次のブロックの先頭までスクロール
  		var $targetOffsetTop = $($target).parents('.js-applyType--target').nextAll('.js-applyType--target').offset().top;
  		$('body,html').animate({
  			scrollTop: $targetOffsetTop
  		}, 400, function(){
        $($target).parents('.js-applyType--target').nextAll('.js-applyType--target').find('.c-apply__heading-lv2').focus();
      });
    }
  }

  function errerModal () {
    // 登録会予約 > 会場予約 > ローディング表示モーダルを準備
    var $modalBgColor = "#fff",
        $modalBgOpacity = ".95";
    $('.js-applyType-nextStep').modaal({
      type: 'inline',
      background: $modalBgColor,
      overlay_opacity: $modalBgOpacity,
      animation_speed: 400,
      is_locked: true,
      hide_close: true,
      content_source: $('#modal-loading'),
      after_close : function(){
        $('.c-loading .loader').removeClass('is-hidden');
        $('.c-loading').find('.c-loading__errMessage').remove();
      }
    });
  }
  errerModal();

  async function handleNextStep(elem) {
    let $errFlag = null;
    // エラーメッセージの生成・表示
    function errMessage (error) {
      // console.log(error);
      $errFlag = 'err';
      $('.c-loading .loader').addClass('is-hidden');
      if(!$('.c-loading').find('.c-loading__errMessage')[0]){
        $('.c-loading').append('<p class="c-loading__errMessage"><span class="u-display--inlineBlock">データが取得できませんでした。</span><span class="u-display--inlineBlock">再度お試しください。</span><button class="c-button__btn--negative js-modal-close">閉じる</button></p>')
      }
      var timerID = setTimeout(function(){
        // ローディング表示を初期設定に戻す処理は、 modal/modal-open.js で設定
        $('.js-applyType-nextStep').modaal('close');
      }, 3500);
    }

    // 会選択した会場 / 日程をアクティブ表示
    $('.c-apply-place__item, .c-apply-place-item__btn, .c-apply-cal-table__btn').removeClass('is-selected');

    // HTMLのレンダリング
    switch (true) {
      case $(elem).parents('.is-place')[0] !== undefined:
        // 会場から選ぶ の場合
        await preparePlaceForm(elem).catch(function(err){
          errMessage(err);
        });
        break;
      case $(elem).parents('.is-date')[0] !== undefined:
        // 日付から選ぶ の場合
        await prepareDateForm(elem).catch(function(err){
          errMessage(err);
        });
        break
      case $(elem).parents('.is-web')[0] !== undefined:
        break
    }

    // 次のブロックを表示
    if (!$errFlag) {
      errerModal();
      goToNextArea(elem);
      $(elem).data('no-scroll', false);
      $('.js-applyType-nextStep').modaal('close');
    }
    
    $errFlag = null;
    //console.log($errFlag);
  }

	$(document).on('click', '.js-applyType-nextStep', async function(e){
    e.preventDefault();
    await handleNextStep(this);
	});

  $(document).on('click', '.js-apply-venueTab-reserveButton', function() {
    const $wrap = $(this).closest('.c-apply-cal');
    $wrap.find('input[name=date]').val($(this).attr('data-date'));
    $wrap.find('input[name=time]').val($(this).attr('data-time'));
  });
  $(document).on('click', '.js-apply-dateTab-reserveButton', function() {
    const $wrap = $(this).closest('.c-apply-place');
    const $itemWrap = $(this).closest('.c-apply-place__item');
    const time = $itemWrap.find('.js-apply-date-selectTime').val();
    $wrap.find('input[name=venue]').val($itemWrap.find('.c-apply-place-item__map').attr('data-venue'));
    $wrap.find('input[name=time]').val(time);
  });
  // サブミットをキャンセル → ダイアログを開く
  $(document).on('click', '.js-apply-venueTab-reserveButton, .js-apply-dateTab-reserveButton, .c-apply-applyType__item.is-web form button.c-button__btn--pt2', function(e) {
    // サブミットをキャンセル
    e.preventDefault();

    // 選択タイプ・選択された会場・日時の取り出し
    var $parent = $(this).closest('.c-apply-applyType__item'),
        $form   = $(this).closest('form'),
        $place,
        $date,
        $time;
    // 「会場から選ぶ」の場合
    if ($parent.hasClass('is-place')) {
      $place  = $parent.find('.c-apply-place-item__btn.is-selected').prevAll('.c-apply-place-item__title').text() + '会場';
      $date   = $(this).attr('data-date');
      $time   = $(this).attr('data-time');
    }
    // 「日付から選ぶ」の場合
    if ($parent.hasClass('is-date')) {
      var $placeItem = $(this).parents('.c-apply-place__item');
      $place  = $placeItem.find('.c-apply-place-item__title').text();
      $date   = $parent.find('.c-apply-cal-table__btn.is-selected').attr('data-date');
      $time   = $placeItem.find('.js-apply-date-selectTime').val();
    }
    // 「WEB面接」の場合
    if ($parent.hasClass('is-web')) {
      $place  = 'WEB面接';
      $date   = $form.find('[name=date]').val();
      $time   = $form.find('[name=time]').val();
    }
    console.log($date);
    var $date_year   = $date.slice(0,4),          // 先頭4桁 = 年
        $date_month  = Number($date.slice(4,6)),  // 5〜6桁 = 月
        $date_day    = Number($date.slice(6));    // 7桁〜 = 日

    // 選択された会場・日時をセット
    $('.js-apply-confirm--venue').text($place);
    $('.js-apply-confirm--year').text($date_year);
    $('.js-apply-confirm--month').text($date_month);
    $('.js-apply-confirm--day').text($date_day);
    $('.js-apply-confirm--time').text($time);

    // ダイアログを表示
    $('.js-apply-confirm-modal').fadeIn();
    _no_scroll()

    // ダイアログで「予約を決定する」を押下
    $(document).on('click', '.js-apply-confirm--submit', function(e){
      e.preventDefault();
      $form.submit();
    });
    // 「選び直す」 or 「×」でダイアログを閉じる
    $(document).on('click', '.js-apply-confirm--close', function(e){
      e.preventDefault();
      _return_scroll()
      $('.js-apply-confirm-modal').fadeOut();
    });
  });

  /**
   * スマホ用メガメニュー展開時に、ボディのスクロールを止める
   */
  var ScrollPositionX, ScrollPositionY;
  function _no_scroll() {
    ScrollPositionY = $(window).scrollTop();
    ScrollPositionX = $(window).scrollLeft();
    $('body').addClass('noscroll').css({'top': (-ScrollPositionY), 'left': (-ScrollPositionX)});
  }
  //スクロール復活関数
  function _return_scroll() {
    $('body').removeClass('noscroll');
    $(window).scrollTop(ScrollPositionY);
  }

  // 応募フローの会場予約が会場検索からのときの初期表示: サーバー側描画に変更
  /*
  if ($('meta[name="x-venue-id"]').length && $('meta[name="x-venue-pref"]').length) {
    const venueId = $('meta[name="x-venue-id"]').attr('content');
    const pref = $('meta[name="x-venue-pref"]').attr('content');
    if (venueId && pref) {
      $('.c-apply-selectArea select').val(pref);
      $('.c-apply-selectArea .js-applyType-nextStep').data('no-scroll', true);
      handleNextStep($('.c-apply-selectArea .js-applyType-nextStep')).then(() => {
        const target = '.c-apply-place .js-applyType-nextStep[data-venue="'+venueId+'"]';
        if ($(target).length) {
          $(target).data('no-scroll', true);
          handleNextStep($(target));
        }
      });
    }
  }
  */
});
